import React, { useContext, useEffect, useState } from "react";
import "./Results.scss";
import { Results, ResultProps } from "./Results";
import { Pagination } from "@mui/material";
import usePagination from "../../Components/Pagination/usePagination";
import { getTenderResult } from "../../Components/Api/GetApi";
import { useSearch } from "../../Components/Context/SearchContext";
import { toast } from "react-toastify";
import { checkDay } from "../../Components/utils/DateConversion";
import NewBanner from "../../Components/NewBanner/NewBanner";
import { NoDataFound } from "../../Components/NoDataFound/NoDataFound";
import { AdShow } from "../../Components/AdShow/AdShow";
import { ResultShimmer } from "./ResultShimmer";
import { useAuth } from "../../Components/Context/AuthContext";
import { GalleryView } from "../../Components/GalleryView/GalleryView";
import { ModalContext } from "../../Components/Context/ModalContext/ModalContext";
import { mdiClose } from "@mdi/js";
import { Icon } from "@mdi/react";
import { ImageSlider } from "../../Components/ImageSlider/ImageSlider";

export interface ResultGroupProps {
  main?: Array<ResultProps>;
}

export const ResultsGroup: React.FC<ResultGroupProps> = ({ main }) => {
  const { isAuthenticate } = useAuth();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [totalResult, setTotalResult] = useState<number>(1);
  const [tenderResult, setTenderResult] = useState<any>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const { query } = useSearch();
  const [ignore, setIgnore] = useState<boolean>(false);
  const [showButton, setShowButton] = useState(false);
  const { TogglePopUpFlags } = useContext(ModalContext);
  const [selectedId, setSelectedId] = useState(0);
  const [openSlider, setOpenSlider] = useState(false);
  const [showView, setShowView] = useState("");

  const allTenderResult = (page: number) => {
    setLoading(true);
    getTenderResult(page, query)
      .then((res) => {
        setTenderResult(res.data.data.results);
        setTotalResult(res.data.data.total);
        setPageSize(res.data.data.page_size);
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (e: any, p: any) => {
    setPage(p);
    _DATA.jump(p);
  };

  const customLoginStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "410px",
    },
  };

  useEffect(() => {
    allTenderResult(page);
  }, [page, query, isAuthenticate]);

  const count = totalResult !== 0 ? Math.ceil(totalResult / pageSize) : 1;
  const _DATA = usePagination(tenderResult, pageSize);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200 && !showButton) {
        setShowButton(true);
        return;
      }
      if (window.scrollY === 0) {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getView = (value: any) => {
    setShowView(value);
  };

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  function toggleImageModal() {
    setOpenSlider(!openSlider);
  }

  const handleOnClick = (val: any) => {
    const tender_id = val.id;
    if (isAuthenticate === true) {
      toggleImageModal();
      setSelectedId(tender_id);
    } else {
      TogglePopUpFlags("login");
    }
  };

  const onhandleClose = () => {
    setOpenSlider(!openSlider);
    setShowView("image");
  };

  return (
    <>
      <div className="cardwrapper-section result-section">
        {openSlider && (
          <>
            <div className="slide-wrapper">
              <div onClick={onhandleClose}>
                {" "}
                <Icon className="mdi-close-image-slider" path={mdiClose} />
              </div>
              <ImageSlider tenderCard={tenderResult} selectedId={selectedId} />
            </div>
          </>
        )}

        {showButton && (
          <img
            className={`scroll-top-btn`}
            src={require("../../images/scroll-top.svg").default}
            alt="scroll-to-top"
            onClick={handleClick}
          />
        )}
        <NewBanner />
        <div className="card-wrapper-content">
          <div className="container">
            <div className="row">
              <div className="col-md-9">
                <div className="cardwrapper-section">
                  <div className="title-details">
                    <div className="row" style={{ width: "100%" }}>
                      <div className="col-md-11">
                        <p className="text-headers result-text">Results </p>
                      </div>
                      <div className="col-md-1">
                        <GalleryView getView={getView} />
                      </div>
                    </div>
                  </div>

                  {loading ? (
                    <div style={{ marginTop: "46px" }}>
                      <ResultShimmer />
                    </div>
                  ) : (
                    <>
                      {tenderResult.length > 0 ? (
                        tenderResult.map((item: any, index: any) => {
                          return (
                            <>
                              <h4 className="time-date" key={index}>
                                {checkDay(item.date)}
                              </h4>
                              <div
                                className={
                                  showView === "image" ? "gallery-view" : ""
                                }
                              >
                                {item.data.map((val: any, id: number) => {
                                  return (
                                    <>
                                      {showView === "list" ? (
                                        <React.Fragment key={id}>
                                          <Results
                                            dataValue={val}
                                            key={id}
                                            ignore={ignore}
                                            setIgnore={setIgnore}
                                            savebids={false}
                                          />
                                        </React.Fragment>
                                      ) : (
                                        val.image && (
                                          <div
                                            onClick={() => {
                                              handleOnClick(val);
                                            }}
                                          >
                                            {isAuthenticate ? (
                                              <img
                                                src={val.image}
                                                alt="Notice"
                                              />
                                            ) : (
                                              <img
                                                src={require("../../images/notice.png")}
                                                alt="Notice"
                                              />
                                            )}
                                          </div>
                                        )
                                      )}
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <div>
                          <NoDataFound
                            text1="No Results found"
                            text2=""
                            img={require("../../images/nodata.png")}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {tenderResult && tenderResult.length > 0 && (
                    <Pagination
                      count={count}
                      size="large"
                      page={page}
                      shape="rounded"
                      onChange={handleChange}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-3" style={{marginTop: "232px"}}>
                <AdShow />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
