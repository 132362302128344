import "./TenderCard.scss";
import { Dispatch, SetStateAction, useContext } from "react";
import { useAuth } from "../Context/AuthContext";
import { tenderBid, saveTenderBid } from "../Api/PostApi";
import { toast } from "react-toastify";
import { catchError } from "../utils/catchError";
import { ignoreTenderBid } from "../Api/PostApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { TenderCardAction } from "../TenderCardAction/TenderCardAction";
import { ModalContext } from "../Context/ModalContext/ModalContext";
import { isBidder, isPublisher } from "../../utils/services";
import { ResponseTenderCard } from "./TenderCardGroup";
import { Award } from "../../Pages/Results/Results";
import { nepaliAmountFormat } from "nepali-number";
import { Link } from "react-router-dom";

export interface Props {
  toggleModal?: () => void;
}

export interface ProcurementType {
  value: number;
  label: string;
}

export interface TenderCardProps {
  ignore: boolean;
  setIgnore: Dispatch<SetStateAction<boolean>>;
  savebids?: boolean;
  dataValue?: ResponseTenderCard;
}

export const TenderCard: React.FC<TenderCardProps> = ({
  ignore,
  setIgnore,
  savebids,
  dataValue,
}) => {
  const { role, isAuthenticate, eligible, userData } = useAuth();
  const { TogglePopUpFlags } = useContext(ModalContext);

  //This function ensures bidder clicking the bid button for first time or not
  const FirstBidCheck = (tender_id: string | number | undefined) => {
    const data = {
      tender_notice_id: tender_id,
    };
    tenderBid(data)
      .then((res) => {
        if (res.status === 202) {
          toast.error(res.data.status.status_message);
        }
        if (res.status === 200) {
          window.open(`/tender-bid/${tender_id}`, "_blank");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return TogglePopUpFlags("login");
        }
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  const saveBids = (tender_id: string | number | undefined) => {
    saveTenderBid(tender_id)
      .then((res) => {
        toast.success("Bid Saved Successfully");
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  const shareUrl = `${
    dataValue?.private
      ? `bids/view/${dataValue?.id}`
      : `tender-notice/${dataValue?.id}`
  }`;

  const navigateToNoticeDetail = (tender_id: string | number | undefined) => {
    if (!tender_id) return;
    if (dataValue?.private && !dataValue?.specification_terms_format) {
      window.open(`/e-notice/view/${tender_id}`, "_blank");
    } else {
      window.open(shareUrl, "_blank");
    }
  };

  const ignoreBids = (tender_id: string | number | undefined) => {
    ignoreTenderBid(tender_id)
      .then((res) => {
        toast.success("Bid Ignored Successfully");
        setIgnore(!ignore);
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  const handleOnClickTitle = () => {
    if (isAuthenticate || dataValue?.private) {
      if (
        isBidder(role) &&
        userData?.plan_remaining_days === 0 &&
        !dataValue?.private
      ) {
        var planUrl = `${window.location.origin}/pricing-plan`;
        window.open(planUrl, "_blank");
      } else {
        navigateToNoticeDetail(dataValue?.id);
      }
    } else {
      TogglePopUpFlags("login");
    }
  };

  const handleOnClickBid = (id: number) => {
    if (isAuthenticate && isBidder(role)) {
      if (eligible) {
        FirstBidCheck(id);
      } else {
        TogglePopUpFlags("editCompanyDetail");
      }
    } else {
      TogglePopUpFlags("login");
    }
  };

  const handleOnClickSave = () => {
    if (isAuthenticate) {
      saveBids(dataValue?.id);
    } else {
      TogglePopUpFlags("login");
    }
  };

  return (
    <div className="tender-card">
      <div className="card">
        <div className="card-section p-section">
          <div className="tender_number-content">
            <div className="tender-header">
              <div className="tag-info">
                <img
                  className="map-icon"
                  src={require("../../images/location_fill.svg").default}
                  alt="images"
                />

                <div className="paragraph-tender">
                  <p className="tender-contents">
                    <span className="tender-bold">{dataValue?.district}</span>
                  </p>
                </div>
              </div>
              <div className="border-line"></div>
              <div className="tag-info">
                <p className=" tender-contents">
                  <span className="tender-bold">
                    {dataValue?.notice_category}
                  </span>
                </p>
              </div>
              <div className="border-line"></div>
              <div className="tag-info">
                <p className=" tender-contents">
                  {dataValue?.source && (
                    <span className="tender-bold">
                      Source: {dataValue?.source}
                    </span>
                  )}
                </p>
              </div>
            </div>

            <div className="tender-document-data">
              <div className="tender_number-number">
                <p className="tender_number-no">
                  TID :
                  <span className="tender-bold">
                    {dataValue?.tender_number}
                  </span>
                </p>
              </div>

              <TenderCardAction
                shareUrl={shareUrl}
                boq={dataValue?.boq}
                tender_id={dataValue?.id}
                savebids={savebids}
                title={dataValue?.title}
                image={dataValue?.image}
                ppmoFileUrl={dataValue?.ppmo_file_url}
                terms={dataValue?.terms}
                notes={dataValue?.notes}
                show_boq_item_rate={dataValue?.show_boq_item_rate}
                opening_date={dataValue?.opening_date}
                category_type={dataValue?.category_type}
                remaining_days={dataValue?.remaining_days}
                handleOnClickBid={handleOnClickBid}
                privateBid={dataValue?.private}
                additional_attributes={dataValue?.additional_attributes}
              />
            </div>
          </div>
          <div>
            <p
              className="tender-title"
              onClick={() => {
                handleOnClickTitle();
              }}
            >
              {dataValue?.title}
            </p>
          </div>
          <div>
            {isBidder(role) || isPublisher(role) ? (
              <>
                {dataValue?.user === userData?.id ? (
                  <Link to={`/`} target="_blank">
                    <p className="sub-title">{dataValue?.public_entity_name}</p>
                  </Link>
                ) : (
                  <Link to={`/publisher/${dataValue?.user}`} target="_blank">
                    <p className="sub-title">{dataValue?.public_entity_name}</p>
                  </Link>
                )}
              </>
            ) : (
              <p className="sub-title">{dataValue?.public_entity_name}</p>
            )}
          </div>
          <div className="tender-border"></div>

          <div className="card-wrapper">
            <div className="d-flex">
              <div className="bid-before">
                <div className="tag-info">
                  <img src={require("../../images/bid.png")} alt="images" />

                  <p className="tender-contents">
                    Published Date:
                    <span className="tender-bold">
                      {dataValue?.publishing_date}
                    </span>
                  </p>
                  {dataValue?.remaining_days !== null && (
                    <>
                      <div className="border-line"></div>
                      <p className="tender-contents">
                        Apply Before:
                        <span className="tender-bold">
                          {dataValue?.submission_date?.slice(0, 10)}
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
              {dataValue?.remaining_days !== null && (
                <div className="days-remain">
                  <p className="days-count">
                    {Number(dataValue?.remaining_days) <= 0 ? (
                      <span>Expired</span>
                    ) : (
                      <span>
                        {dataValue?.remaining_days + " Days Remaining"}
                      </span>
                    )}
                  </p>
                </div>
              )}
            </div>

            <div className="bid-before-first-section  bidbefore-section">
              {(!isAuthenticate || isBidder(role)) &&
                dataValue?.private &&
                dataValue?.specification_terms_format &&
                Number(dataValue?.remaining_days) > 0 && (
                  <div className="btn-combination">
                    <div className="mg-btn-left">
                      <button
                        className="transparent-btn"
                        onClick={() => {
                          handleOnClickBid(dataValue?.id as number);
                        }}
                      >
                        <img
                          className="btn-img-icon-size"
                          src={require("../../images/bidblue.png")}
                          alt="images"
                        />
                        Apply Bid
                      </button>
                    </div>
                  </div>
                )}
              {savebids ? (
                <div>
                  {isAuthenticate && (
                    <div className="mg-btn-left">
                      <button
                        className="save-bid-btn"
                        onClick={() => {
                          ignoreBids(dataValue?.id);
                        }}
                      >
                        Ignore
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {(!isAuthenticate || isBidder(role)) &&
                    (!dataValue?.private ||
                      !dataValue?.specification_terms_format) && (
                      <div className="mg-btn-left">
                        <button
                          className="save-bid-btn"
                          onClick={() => {
                            handleOnClickSave();
                          }}
                        >
                          <FontAwesomeIcon className="icon-bg" icon={faSave} />
                          Save Bid
                        </button>
                      </div>
                    )}
                </>
              )}
            </div>
          </div>
          {dataValue?.award_result && dataValue?.award_result?.length > 0 && (
            <div>
              <div className="work-tender-border"></div>
              <div className="work-img mg-right-8">
                <i className="bi bi-bag img"></i>
                <span className="work-header">Work: </span>
              </div>
              <ul className="award-section">
                {dataValue?.award_result?.map((award: Award, index: number) => {
                  return (
                    <li className="award-content" key={index}>
                      {`Tender ID: ${award.tender_number ?? "-"}, ` +
                        (award.company ||= "N/A") +
                        `(Rs. ${
                          award.price ? nepaliAmountFormat(award.price) : "N/A"
                        })`}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
