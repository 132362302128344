import { useState } from "react";
import RegisterFormikWrapper from "../FormikWrapper/RegisterFormikWrapper";
import { OnbordingOne } from "../StepperOne/StepperOne";
import { OnbordingTwo } from "../StepperTwo/StepperTwo";
import "./Stepper.scss";

export const Stepper = () => {
  const [Page, setPage] = useState(1);
  const [check1, setCheck1] = useState(true);
  const [check2, setCheck2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [skip, setSkip] = useState(false);
  const [skipLoading, setSkipLoading] = useState(false);

  return (
    <RegisterFormikWrapper
      page={Page}
      setPage={setPage}
      loading={loading}
      setLoading={setLoading}
      skipLoading={skipLoading}
      setSkipLoading={setSkipLoading}
      skip={skip}
    >
      <div className="Stepper">
        <div className="card-section">
          <div className="form-section">
            <button
              type="button"
              className={Page === 1 ? "btn primary-btn" : "btn white-btn"}
              onClick={() => {
                setPage(1);
              }}
            >
              Personal Details
            </button>
            <button
              type="button"
              disabled
              className={Page === 2 ? "btn primary-btn" : "btn white-btn"}
              onClick={() => {
                setPage(2);
              }}
            >
              Organization Details
            </button>
          </div>

          {Page === 1 ? (
            <OnbordingOne
              setPage={setPage}
              Page={Page}
              check1={check1}
              setCheck1={setCheck1}
              check2={check2}
              setCheck2={setCheck2}
              setSkipValue={setSkip}
            />
          ) : (
            <OnbordingTwo
              loading={loading}
              Page={Page}
              setPage={setPage}
              setSkipValue={setSkip}
              skipLoading={skipLoading}
            />
          )}
        </div>
      </div>
    </RegisterFormikWrapper>
  );
};
