import { Pagination } from "@mui/material";
import React, { useEffect, useState } from "react";
import usePagination from "../Pagination/usePagination";
import { getAllTenderNotice } from "../Api/GetApi";
import { TenderCard } from "../TenderCard/TenderCard";
import "../TenderCard/TenderCard.scss";
import { ResultShimmer } from "../../Pages/Results/ResultShimmer";
import { useAuth } from "../Context/AuthContext";
import { useGlobal } from "../Context/GlobalContext";
import { useSearch } from "../Context/SearchContext";
import { NoDataFound } from "../NoDataFound/NoDataFound";
import { checkDay } from "../utils/DateConversion";
import { catchError } from "../utils/catchError";
import { TenderCardAd } from "../TenderCard/TenderCardAd";
import { ResponseTenderCard } from "../TenderCard/TenderCardGroup";

export interface NewTenderCardProps {
  date?: string;
  data?: Array<ResponseTenderCard>;
}


export const ENoticePage = () => {
  const { advertisements } = useGlobal();
  const [tenderCard, setTenderCard] = useState<any>([]);
  const [totalTender, setTotalTender] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(0);
  const [loading, setLoading] = useState<Boolean>(true);
  const { query, page, setPage } = useSearch();
  const [ignore, setIgnore] = useState<boolean>(false);
  const { isAuthenticate } = useAuth();
  var adIndex = 0;
  // This function is made to get all the tenders
  const getTenders = (page: number, notice_type:string) => {
    setLoading(true);
    return getAllTenderNotice(page, query, true, notice_type)
      .then((res) => {
        setTenderCard(res.data.data.results);
        setTotalTender(res.data.data.total);
        setPageSize(res.data.data.page_size);
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (e: any, p: any) => {
    window.scrollTo({ top: 0 });
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    getTenders(page, "E-Notice");
  }, [page, query, isAuthenticate]);

  const count: number = Math.ceil(Number(totalTender) / Number(pageSize));
  const _DATA = usePagination(tenderCard, pageSize);

  return (
    <div className="cardwrapper-section">
      <div>
        {loading ? (
          <ResultShimmer />
        ) : (
          <>
            {tenderCard && tenderCard.length > 0 ? (
              tenderCard.map((item: NewTenderCardProps, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <h4 className="time-date">{checkDay(item.date)}</h4>
                    <div className="">
                      {(item.data as ResponseTenderCard[]).map(
                        (val: ResponseTenderCard, id: number) => {
                          return (
                            <>
                              <React.Fragment key={id}>
                                <TenderCard
                                  key={id}
                                  ignore={ignore}
                                  setIgnore={setIgnore}
                                  savebids={false}
                                  dataValue={val}
                                />

                                {page === 1 &&
                                  (id + 1) % 3 === 0 &&
                                  advertisements &&
                                  advertisements["In-Between-Notice"]?.[
                                    adIndex
                                  ] && (
                                    <div className="notice-in-between-card">
                                      <TenderCardAd
                                        advertisement={
                                          advertisements &&
                                          advertisements["In-Between-Notice"]?.[
                                            adIndex
                                          ]
                                        }
                                      />
                                      <div style={{ display: "none" }}>
                                        {adIndex++}
                                      </div>
                                    </div>
                                  )}
                              </React.Fragment>
                            </>
                          );
                        }
                      )}
                    </div>
                  </React.Fragment>
                );
              })
            ) : (
              <div>
                <NoDataFound
                  text1="No tenders found"
                  img={require("../../images/nodata.png")}
                />
              </div>
            )}
            {tenderCard && tenderCard.length > 0 && (
              <Pagination
                count={count}
                size="large"
                page={page}
                shape="rounded"
                onChange={handleChange}
                siblingCount={0}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
