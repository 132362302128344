import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { register } from "../Api/PostApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { catchError } from "../utils/catchError";
import { Role } from "../../utils/EnumConstants";

export const initialvalues = {
  role: Role.Bidder,
  sign_up_as: "",
  first_name: "",
  last_name: "",
  middle_name: "",
  password: "",
  email: "",
  contact_no: "",
  company_name: "",
  organization_category: "",
  office_email: "",
  website_url: "",
  province: "",
  district: "",
  municipality: "",
  company_contact_no: "",
  registration_certificate_front: "",
  registration_certificate_back: "",
  pan_vat_certificate: "",
  liscense_front: "",
  liscense_back: "",
  country_code: "",
  logo: null,
  introduction: null,
  estd: null,
  employee_no: null,
  skip: "false",
};

export const validationSchema1 = Yup.object({
  role: Yup.string().required("Required"),
  sign_up_as: Yup.string().when("role", {
    is: (role: string) => role === Role.Bidder,
    then: Yup.string().required("Required"),
  }),
  first_name: Yup.string()
    .min(2, "Name too small")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces")
    .required("Required"),
  middle_name: Yup.string()
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces")
    .min(2, "Name too small"),
  // address: Yup.string().required('Required'),
  last_name: Yup.string()
    .min(2, "Name too small")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces")
    .required("Required"),
  contact_no: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(11, "Phone number is not valid.")
    .max(13, "Phone number is not valid."),

  email: Yup.string().email("Invalid email address").required("Required"),

  password: Yup.string()
    .min(8, "More than 8 characters or more")
    .required("Required"),
  confirmpassword: Yup.string()
    .required("Required")
    .when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "Password Doesnot Match"),
    }),
});

export const validationSchema2 = Yup.object({
  role: Yup.string().required("Required 111"),
  sign_up_as: Yup.string().when("role", {
    is: (role: string) => role === Role.Bidder,
    then: Yup.string().required("Required"),
  }),
  organization_category: Yup.string().when("role", {
    is: (role: string) => role === Role.Publisher,
    then: Yup.string().required("Required"),
  }),
  first_name: Yup.string()
    .min(2, "Name too small")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces")
    .required("Required"),
  middle_name: Yup.string()
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .min(2, "Name too small"),
  // address: Yup.string().required('Required'),
  last_name: Yup.string()
    .min(2, "Name too small")
    .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces")
    .required("Required"),
  contact_no: Yup.string()
    .required("Required")
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(11, "Phone number is not valid.")
    .max(13, "Phone number is not valid."),

  email: Yup.string().email("Invalid email address").required("Required"),

  password: Yup.string()
    .min(8, "More than 8 characters or more")
    .required("Required"),
  confirmpassword: Yup.string()
    .required("Required")
    .when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "Password Doesnot Match"),
    }),

  company_name: Yup.string()
    .required("Required")
    .matches(/^(?!\s+$).*/, "* This field cannot contain only blankspaces"),
  office_email: Yup.string().email("Invalid email address"),
  website_url: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    "Enter correct url!"
  ),
  province: Yup.string().required("Required"),
  district: Yup.string().required("Required"),
  municipality: Yup.string().required("Required"),
  company_contact_no: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Phone number is not valid"
    )
    .min(7, "Phone number is not valid")
    .max(13, "Phone number is not valid"),

  registration_certificate_front: Yup.mixed()
    .test(
      "registration_certificate_front",
      "File size exceeds maximum limit of 3MB",
      (value) => !value || (value && value.size <= 3000000)
    )
    .test(
      "fileFormat",
      "Company Registration Certificate (Front) Required! Pdf only",
      (value) => {
        return value && ["application/pdf"].includes(value.type);
      }
    ),

  registration_certificate_back: Yup.mixed()
    .test(
      "registration_certificate_back",
      "File size exceeds maximum limit of 3MB",
      (value) => !value || (value && value.size <= 3000000)
    )
    .test(
      "fileFormat",
      "Company Registration Certificate (Back) Required! Pdf only",
      (value) => {
        if (value) {
          return ["application/pdf"].includes(value.type);
        }
        return true;
      }
    ),

  pan_vat_certificate: Yup.mixed()
    .test(
      "pan_vat_certificate",
      "File size exceeds maximum limit of 3MB",
      (value) => !value || (value && value.size <= 3000000)
    )
    .test("fileFormat", "PAN / VAT Certificate Required! Pdf only", (value) => {
      return value && ["application/pdf"].includes(value.type);
    }),

  liscense_front: Yup.mixed()
    .test(
      "liscense_front",
      "File size exceeds maximum limit of 3MB",
      (value) => !value || (value && value.size <= 3000000)
    )
    .test("fileFormat", "License (Front) Required! Pdf only", (value) => {
      if (value) {
        return ["application/pdf"].includes(value.type);
      }
      return true;
    }),
  liscense_back: Yup.mixed()
    .test(
      "liscense_back",
      "File size exceeds maximum limit of 3MB",
      (value) => !value || (value && value.size <= 3000000)
    )
    .test("fileFormat", "License (Back) Required! Pdf only", (value) => {
      if (value) {
        return ["application/pdf"].includes(value.type);
      }
      return true;
    }),

  logo: Yup.mixed().when("role", {
    is: (role: string) => role === Role.Publisher,
    then: Yup.mixed()
      .test(
        "logo",
        "File size exceeds maximum limit of 3MB",
        (value) => !value || (value && value.size <= 3000000)
      )
      .test("fileFormat", "Company Logo Required! Image only", (value) => {
        return (
          value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
        );
      }),
    otherwise: Yup.mixed(),
  }),
  
  introduction: Yup.string().when("role", {
    is: (role: string) => role === Role.Publisher,
    then: Yup.string().required("Required"),
    otherwise: Yup.string(),
  }),
});
const formData = new FormData();

export interface FormikWrapperProps {
  children: any;
  page: number;
  setPage: (page: number) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  skip: boolean;
  setSkipLoading: (skipLoading: boolean) => void;
  skipLoading: boolean;
}

const RegisterFormikWrapper: React.FC<FormikWrapperProps> = ({
  children,
  page,
  setPage,
  loading,
  skipLoading,
  setLoading,
  setSkipLoading,
  skip,
}) => {
  // const [validationSchema,setValidationSchema]=React.useState(validationSchema1);
  const navigate = useNavigate();
  const registerUser = (formData: any, setSubmitting: any, resetForm: any) => {
    if (skip === true) {
      setSkipLoading(true);
    } else {
      setLoading(true);
    }
    register(formData)
      .then((res) => {
        if (res.status === 200) {
          resetForm();
          if (res.data?.data?.role === Role.Bidder) {
            toast.success("User Registered Successfully.");
            navigate(`/interest/${res.data?.data?.user_id}`);
          } else {
            toast.success(res.data.status.status_message);
            navigate("/");
          }
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        if (skip === true) {
          setSkipLoading(false);
        } else {
          setLoading(false);
        }
      });
  };

  return (
    <Formik
      initialValues={initialvalues}
      onSubmit={async (
        values: typeof initialvalues,
        { setSubmitting, resetForm }
      ) => {
        Object.entries(values).forEach(([key, value]) => {
          if (value !== null) {
            formData.append(key, value);
          }
        });
        if (page === 2 || skip) {
          registerUser(formData, setSubmitting, resetForm);
        } else {
          setPage(2);
        }
      }}
      validationSchema={
        page === 1 ? validationSchema1 : skip === false ? validationSchema2 : ""
      }
      validateOnChange={true}
      validateOnBlur={true}
    >
      <Form>{children}</Form>
    </Formik>
  );
};

export default RegisterFormikWrapper;
