import { Procurement } from "../Procurement/Procurement";
import { RegisterInfo } from "../RegisterInfo/RegisterInfo";
import "./UserInterest.scss";

export const UserInterest: React.FC = ({}) => {
  return (
    <div className="user-interest section-gap">
      <div className="container">
        <div className="row">
          <div className="col-md-4 pr-0">
            <RegisterInfo />
          </div>
          <div className="col-md-8 pl-0">
            <Procurement showEdit={false} />
          </div>
        </div>
      </div>
    </div>
  );
};
