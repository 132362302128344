import { ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { NepaliDistricts } from "../../Datas/Districts";
import { getDateDifferenceInDays } from "../../utils/services";
import { getTenderAttrs } from "../Api/GetApi";
import { useENoticeFormik } from "../FormikWrapper/ENoticeFormikWrapper";
import { CustomLoader } from "../Loader/Loader";
import { NoteComponent } from "../NoteComponent/NoteComponent";
import { ConfirmationPopUp } from "../PopUp/ConfirmationPopUp";
import CustomSelect, { Option } from "../SelectComponent/SelectComponent";
import { addDaysInDate } from "../utils/generic";
import "./NewENotice.scss";
import { CustomCkEditor } from "../customCkEditor/customCkEditor";
interface Props {
  loading?: any;
  publishLoad?: boolean;
}

export const NewENotice: React.FC<Props> = ({ loading, publishLoad }) => {
  const { values, setFieldValue, errors, handleSubmit } = useENoticeFormik();

  const [noticeCat, setNoticeCat] = useState<Option[]>([]);
  const [projectCat, setProjectCat] = useState<Option[]>([]);
  const [minSubmissionDate, setMinSubmissionDate] = useState("");
  const [minPublishingDate, setMinPublishingDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const fetchCategory = async () => {
    try {
      const res = await getTenderAttrs();
      if (res.status === 200) {
        setNoticeCat(res.data.data.notice_category);
        setProjectCat(res.data.data.project_category);

        if (values.notice_category?.value) {
          setFieldValue(
            "notice_category.label",
            res.data.data.notice_category.find(
              (each: Option) => each.value === values.notice_category?.value
            ).label
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const handleSubmissionDateUpdate = (
    publishingDate: string,
    submissionDate: string
  ) => {
    const differenceInDays = getDateDifferenceInDays(
      publishingDate,
      submissionDate
    );
    if (differenceInDays < 5) {
      const submissionDate = addDaysInDate(publishingDate, 5);
      setMinSubmissionDate(submissionDate);
      setFieldValue("submission_date", submissionDate);
      return;
    }
    setFieldValue("submission_date", submissionDate);
  };

  useEffect(() => {
    const submissionDate = addDaysInDate(values.publishing_date, 5);
    setMinSubmissionDate(submissionDate);
  }, [values.publishing_date]);

  useEffect(() => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const minPublishingDate = currentDate.toISOString().split("T")[0];
    setMinPublishingDate(minPublishingDate);
    setMinSubmissionDate(addDaysInDate(minPublishingDate, 5));
  }, []);

  const customLoginStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "40%",
    },
  };

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        style={customLoginStyles}
        ariaHideApp={false}
      >
        <ConfirmationPopUp
          popUpContents={{
            question1: "Are you sure want to publish notice?",
            // warn: "Your tender would be published",
            aprroveBtn: "Yes",
            cancelBtn: "Cancel",
          }}
          toggleModal={toggleModal}
          activeButtonLoading={publishLoad}
          onAprroveButton={() => {
            setFieldValue("saved", false);
            toggleModal();
            if (errors && Object.keys(errors).length > 0) {
              toast.error("You have got unresolved error");
              return;
            }
            handleSubmit();
          }}
          acceptTerm={true}
          publisherTerm={true}
        />
      </Modal>

      <div className="new-enotice">
        <div className="mb-3">
          <div className="outer-box">
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">
                  Notice Title <span className="red">*</span>
                </label>
                <Field
                  placeholder="Enter Notice Title"
                  className="form-control"
                  name="title"
                  aria-describedby="emailHelp"
                />
                <span className="error">
                  <ErrorMessage name="title" />
                </span>
              </div>

              <div className="col-md-6">
                <label className="form-label">
                  Notice Category<span className="red">*</span>
                </label>
                <Field
                  className=""
                  value={values.notice_category}
                  options={noticeCat}
                  component={CustomSelect}
                  placeholder="Select notice category"
                  isMulti={false}
                  onChange={(selected: Option) => {
                    setFieldValue("notice_category", selected);
                  }}
                />
                <span className="error">
                  <ErrorMessage name="notice_category" />
                </span>
              </div>

              <div className="col-md-6">
                <label className="form-label">
                  Project Category<span className="red">*</span>
                </label>
                <Field
                  className=""
                  name="project_category"
                  options={projectCat}
                  component={CustomSelect}
                  placeholder="Select Project Category"
                  isMulti={true}
                />

                <span className="error">
                  <ErrorMessage name="project_category" />
                </span>
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  Work Location<span className="red">*</span>
                </label>
                <Field
                  className=""
                  name="work_locations"
                  options={NepaliDistricts}
                  component={CustomSelect}
                  placeholder="Select work location"
                  isMulti={true}
                />
                <span className="error">
                  <ErrorMessage name="work_locations" />
                </span>
              </div>

              <div className="col-md-6">
                <label className="form-label">
                  Publishing Date <span className="red">*</span>
                </label>
                <Field
                  type="date"
                  placeholder="Select Publishing Date"
                  className="form-control"
                  value={values?.publishing_date}
                  onChange={(e: any) => {
                    setFieldValue("publishing_date", e.target.value);
                    handleSubmissionDateUpdate(
                      e.target.value,
                      values?.submission_date as string
                    );
                  }}
                  min={minPublishingDate}
                />
                <span className="error">
                  <ErrorMessage name="publishing_date" />
                </span>
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  Last Submission Date /Deadline <span className="red">*</span>
                </label>

                <Field
                  name="submission_date"
                  type="date"
                  className="form-control"
                  value={values?.submission_date}
                  min={minSubmissionDate}
                />
                <span className="error">
                  <ErrorMessage name="submission_date" />
                </span>
                <NoteComponent
                  note={`Last Bids submission time will be 12:00 PM and opening date will be 2:00 PM on same date of submission day.`}
                  traingle={true}
                />
              </div>
              <div className="col-md-12">
                <label className="form-label">Description</label>
                <CustomCkEditor
                  dataValue={values.notes}
                  setFieldValue={(data: string) => {
                    setFieldValue("notes", data);
                  }}
                />
                <span className="error">
                  <ErrorMessage name="notes" />
                </span>
              </div>
            </div>
            <div className="line"></div>

            <div className="col-md-12 right">
              <button
                className="btn transparent-btn btn-edit mr-2"
                type="submit"
                onClick={() => {
                  setFieldValue("saved", true);
                }}
              >
                {loading ? (
                  <CustomLoader
                    type="TailSpin"
                    color="#FFFFFF"
                    height={22}
                    width={33}
                  />
                ) : (
                  <span>Save as Draft</span>
                )}
              </button>
              <button
                type="button"
                disabled={
                  loading || publishLoad || Object.keys(errors).length > 0
                }
                className="btn btn-edit primary-btn"
                onClick={() => {
                  toggleModal();
                }}
              >
                Publish
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
