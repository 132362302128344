import { useAuth } from "../Context/AuthContext"
import "./Interest.scss"
import { mdiPencil } from "@mdi/js"
import { Icon } from "@mdi/react"
import { useEffect, useState } from "react"
import Modal from "react-modal"

import { UserInterest } from "../UserInterest/UserInterest"
import { Procurement } from "../Procurement/Procurement"

export interface ResponseInterest {
  name: string
}

export const Interest = () => {
  const { userData } = useAuth()
  const { procurement_type, project_category } = userData
  const [editOpen, setEditOpen] = useState(false)

  const toggleEditOpen = () => {
    setEditOpen(!editOpen)
  }

  const customClass = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxHeight: "600px",
      width: "80%",
      zIndex: 50,
    },
  }
  return (
    <div className="interest">
      <Modal
        isOpen={editOpen}
        onRequestClose={toggleEditOpen}
        contentLabel="My dialog"
        style={customClass}
        ariaHideApp={false}
      >
        <Procurement
          showEdit={true}
          savedProject={project_category}
          setEditOpen={setEditOpen}
        />
      </Modal>
      <div className="interest-content">
        <div style={{ width: "100%" }}>
          <div className="interest-block">
            <div className="content-block">
              <p className="content">Procurement Types</p>
            </div>
            <div className="content-block">
              <ul>
                {procurement_type?.map((item: ResponseInterest) => {
                  return <li className="content">{item?.name}</li>
                })}
              </ul>
            </div>
          </div>

          <div className="interest-block">
            <div className="content-block">
              <p className="content">Projects</p>
            </div>
            <div className="content-block">
              <ul>
                {project_category?.map((item: ResponseInterest) => {
                  return <li className="content">{item?.name}</li>
                })}
              </ul>
            </div>
          </div>
        </div>

        <button
          className="btn-name btn-edit btn-edit-icon"
          onClick={() => {
            setEditOpen(true)
          }}
        >
          <div className="d-flex">
            <div className="icon-size ">
              <Icon path={mdiPencil} />
            </div>
            Edit
          </div>
        </button>
      </div>
    </div>
  )
}
