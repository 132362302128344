import { mdiFacebook, mdiFacebookMessenger, mdiShareVariant } from "@mdi/js";
import Icon from "@mdi/react";
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton
} from "react-share";
import { ETenderContentShimmer } from "../ETenderContentShimmer/ETenderContentShimmer";
import { NoDataFound } from "../NoDataFound/NoDataFound";
import "./ENoticeContent.scss";

export interface ENoticeContentProps {
  noticeData?: string;
  noticeLoading?: boolean;
  noticeTitle?: string;
}

const ENoticeContent: React.FC<ENoticeContentProps> = ({
  noticeData,
  noticeLoading,
  noticeTitle,
}) => {
  const shareUrl = window.location.href;
  return (
    <>
      {noticeLoading ? (
        <ETenderContentShimmer />
      ) : (
        <>
          <div className="enotice-content">
            {noticeData ? (
              <div className="enotice-details-section">
                <div
                  className="enotice-content-section"
                  dangerouslySetInnerHTML={{
                    __html: noticeData as string,
                  }}
                />
                <div className="enotice-footer">
                  {/* <div className="share-count">
                    <p className="share-title">59</p>
                    <p className="share-content">Shares</p>
                  </div> */}
                  <div className="share-icon">
                    <div className="icon-div">
                      <FacebookShareButton
                        url={shareUrl}
                        quote={noticeTitle}
                        className="mdi-icon fb mg-r color-gradient"
                      >
                        <Icon path={mdiFacebook} className="mdi-social" />
                      </FacebookShareButton>
                    </div>
                    <div className="icon-div">
                      <FacebookMessengerShareButton
                        url={shareUrl}
                        appId="521270401588372"
                        className="mdi-icon fb mg-r color-gradient"
                      >
                        <Icon
                          path={mdiFacebookMessenger}
                          className="mdi-social"
                        />
                      </FacebookMessengerShareButton>
                    </div>
                    <div className="icon-div">
                      <EmailShareButton
                        url={shareUrl}
                        title={noticeTitle}
                      >
                        <Icon path={mdiShareVariant} className="mdi-social" />
                      </EmailShareButton>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <NoDataFound
                text1="No Notes"
                text2="You can see notes here"
                img={require("../../images/nodata.png")}
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ENoticeContent;
