import { BrowserRouter, Routes, Route } from "react-router-dom";
import SearchProvider from "../Context/SearchContext";
import { NavBar } from "../NavBar/NavBar";
import { HomePage } from "../../Pages/HomePage/HomePage";
import { BidDetailPage } from "../BidDetailPage/BidDetailPage";
import { ResultsGroup } from "../../Pages/Results/ResultsGroup";
import { RegisterForm } from "../RegisterForm/RegisterForm";
import {
  RequireAuth,
  RequireBidderAuth,
  EligibleForPublishorBid,
  ActiveUsers,
} from "../AuthRouting/AuthRouter";
import { MyProfile } from "../MyProfile/MyProfile";
import { MyBidsPage } from "../MyBidsPage/MyBidsPage";
import { RequirePublishAuth } from "../AuthRouting/AuthRouter";
import { Footer } from "../Footer/Footer";
import { ToastContainer } from "react-toastify";
import { useAuth } from "../Context/AuthContext";
import { NotFound } from "../NotFound/NotFound";
import { EditTenderPage } from "../EditTender/EditTenderPage";
import { BidsViewPage } from "../BidsViewPage/BidsViewPage";
import { BidderDetail } from "../BidderDetail/BidderDetail";
import { EditBidPage } from "../EditBids/EditBids";
import PublishTenderStepper from "../PublishTenderStepper/PublishTenderStepper";
import { EsewaFeeVerification } from "../BidFeeVerification/EsewaFeeVerification";
import { PasswordResetPage } from "../../Pages/PasswordReset/PasswordResetPage";
import { UserEmailVerification } from "../UserEmailVerification/UserEmailVerification";
import { PaymentPopUp } from "../PaymentPopUp/PaymentPopUp";
import { MyBidLoader } from "../MyBidLoader/MyBidLoader";
import { SavedBids } from "../../Pages/SavedBids/SavedBids";
import { PettyPage } from "../../Pages/Petty/Petty";
import { SingleNoticePage } from "../../Pages/SingleNoticePage/SingleNoticePage";
import { UserInterest } from "../UserInterest/UserInterest";
import { PricingPlan } from "../PricingPlan/PricingPlan";
import { Login } from "../Login/Login";
import Modal from "react-modal";
import { ModalContext } from "../Context/ModalContext/ModalContext";
import { useContext, useEffect, useState } from "react";
import { ForgetPw } from "../Login/ForgetPw/ForgetPw";
import { KhaltiBidFeeVerification } from "../KhaltiPaymentVerification/KhaltiPaymentVerfication";
import MobilePageCheck from "../MobilePage/MobilePage";
import PublisherPolicy from "../../Pages/Company/PublisherPolicy";
import CompanyPolicy from "../../Pages/Company/CompanyPolicy";
import { useGlobal } from "../Context/GlobalContext";
import { getAllAdvertisements } from "../Api/GetApi";
import { ContactUS } from "../ContactUS/ContactUS";
import Bidder from "../../Pages/Company/BidderPolicy";
import BidderTerm from "../../Pages/Company/BidderTerm";
import { AboutUs } from "../../Pages/Company/AboutUs";
import EditCompanyDetails from "../EditDetails/EditCompanyDetails";
import { EnlistmentQuotation } from "../EditTender/EnlistmentQuotation";
import AccountDeletion from "../AccountDeletion/AccountDeletion";
import PublishENotice from "../PublishENotice/PublishENotice";
import { ENoticePage } from "../../Pages/ENoticePage/ENoticePage";
import { PublisherHomePage } from "../../Pages/PublisherHomePage/PublisherHomePage";
import { isPublisher } from "../../utils/services";
import { EditENotice } from "../EditENotice/EditENotice";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
// import Icon from "@mdi/react";
// import { mdiClose } from "@mdi/js";

const ConditionalHomePage = () => {
  const { isAuthenticate, role } = useAuth();
  return isAuthenticate && isPublisher(role) ? (
    <PublisherHomePage />
  ) : (
    <HomePage />
  );
};

const Router = () => {
  //TODO: uncomment when there is boostrap cdn issue
  // const showAlert = localStorage.getItem("showAlert") === "false" ? false: true;
  const { loading, userData } = useAuth();
  const { TogglePopUpFlags, popupFlags } = useContext(ModalContext);
  //TODO: uncomment when there is boostrap cdn issue
  // const [openNoticePopUp, setOpenNoticePopUp] = useState(showAlert);
  const { setAdvertisement, setLoadingAd } = useGlobal();
  const isMobileView = window.innerWidth < 500;
  const mobileViewAccessRoute = [
    "/contact",
    "/user/registration_verification/",
    "/about-us",
    "/privacy-policy",
    "/publisher-policy",
    "/bidder-policy",
    "/bidder-term",
    "/pricing-plan",
  ];

  const customLoginStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "410px",
    },
  };

  const customStylesFP = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "410px",
    },
  };

  //TODO: uncomment when there is boostrap cdn issue
  // const customStylesAlert = {
  //   content: {
  //     top: "50%",
  //     left: "50%",
  //     right: "auto",
  //     bottom: "auto",
  //     marginRight: "-50%",
  //     transform: "translate(-50%, -50%)",
  //     width: "410px",
  //     padding: 0,
  //     border: "none",
  //   },
  // };

  const customStylesOrg = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "75%",
      padding: "20px",
      maxHeight: "700px",
    },
  };

  const getAdvertisements = () => {
    getAllAdvertisements()
      .then((res) => {
        setAdvertisement(res.data.data);
      })
      .finally(() => {
        setLoadingAd(false);
      });
  };

  useEffect(() => {
    getAdvertisements();
  }, []);

  return (
    <>
      {isMobileView &&
      !mobileViewAccessRoute.some((str) =>
        window.location.pathname.includes(str)
      ) ? (
        <MobilePageCheck />
      ) : (
        <>
          {loading ? (
            <>
              <div className="spinner">
                <MyBidLoader />
              </div>
            </>
          ) : (
            <>
              <SearchProvider>
                <BrowserRouter>
                  <NavBar />
                  {/* Modal Section */}
                  {/* TODO: uncomment when there is boostrap cdn issue */}
                  {/* <Modal
                    isOpen={openNoticePopUp}
                    onRequestClose={() => {
                      setOpenNoticePopUp(false);
                    }}
                    contentLabel="My dialog"
                    style={customStylesAlert}
                    ariaHideApp={false}
                  >
                    <div
                      style={{ backgroundColor: "#0052a7", padding: "10px 20px 20px 20px" }}
                    >
                      <div
                        className="close-icon"
                        onClick={() => {
                          localStorage.setItem("showAlert", "false");
                          setOpenNoticePopUp(false);
                        }}
                      >
                        <Icon className="mdi-close" path={mdiClose} style={{color: "white"}} />
                      </div>
                      <FontAwesomeIcon
                        style={{
                          color: "white",
                          fontSize: "30px",
                          width: "100%",
                        }}
                        icon={faExclamationTriangle}
                      />
                    </div>

                    <p
                      className="sub-title"
                      style={{
                        lineHeight: "1.8",
                        fontSize: "14px",
                        padding: "14px",
                        textAlign: "justify",
                      }}
                    >
                      We are sorry to inform that, due to ongoing technical
                      issues, you may experience some layout errors in the
                      website. Our team are working on to fix the error soon.
                    </p>
                    <p
                      className="tender-bold"
                      style={{ padding: "14px", fontSize: "14px" }}
                    >
                      Thank You
                    </p>
                    <img
                      src={require("../../images/logo.svg").default}
                      alt="logo"
                      style={{ width: "50%", padding: "0 14px 14px 14px" }}
                    />
                  </Modal> */}

                  <Modal
                    isOpen={popupFlags.forgetPw}
                    onRequestClose={() => {
                      TogglePopUpFlags("forgetPw");
                    }}
                    contentLabel="My dialog"
                    style={customStylesFP}
                    ariaHideApp={false}
                  >
                    <ForgetPw />
                  </Modal>

                  <Modal
                    isOpen={popupFlags.login}
                    onRequestClose={() => {
                      TogglePopUpFlags("login");
                    }}
                    contentLabel="My dialog"
                    style={customLoginStyles}
                    ariaHideApp={false}
                  >
                    <Login />
                  </Modal>

                  <Modal
                    isOpen={popupFlags.editCompanyDetail}
                    onRequestClose={() => TogglePopUpFlags("editCompanyDetail")}
                    contentLabel="My dialog"
                    style={customStylesOrg}
                    ariaHideApp={false}
                  >
                    <EditCompanyDetails
                      toggleModal={() => TogglePopUpFlags("editCompanyDetail")}
                      userData={userData}
                    />
                  </Modal>
                  {/* End Modal Section */}
                  {/* Route Section */}

                  <Routes>
                    #Public Routes
                    <Route path="/" element={<ConditionalHomePage />} />
                    <Route
                      path="/user/password_reset/:id"
                      element={<PasswordResetPage />}
                    />
                    <Route
                      path="/user/registration_verification/:id"
                      element={<UserEmailVerification />}
                    />
                    <Route
                      path="/e-notice/view/:id"
                      element={<ENoticePage />}
                    />
                    <Route path="/bids/view/:id" element={<BidsViewPage />} />#
                    Auth Guard Routes
                    <Route element={<RequireAuth />}>
                      <Route path="/my-profile" element={<MyProfile />} />
                      <Route
                        path="/publisher/:id"
                        element={<PublisherHomePage />}
                      />
                      <Route element={<ActiveUsers />}>
                        <Route
                          path="/tender-notice/:id"
                          element={<SingleNoticePage />}
                        />
                        <Route
                          path="/notices/:id"
                          element={<SingleNoticePage />}
                        />
                      </Route>

                      <Route
                        path={`/pricing-plan/khalti/payment_verification`}
                        element={<KhaltiBidFeeVerification />}
                      />
                      <Route
                        path={`/pricing-plan/esewa/payment_verification`}
                        element={<EsewaFeeVerification />}
                      />
                      <Route
                        path="bids/view/:id/:bidder_id"
                        element={<BidderDetail />}
                      />
                    </Route>
                    {/* Routes for Bidder */}
                    <Route element={<RequireBidderAuth />}>
                      <Route element={<EligibleForPublishorBid />}>
                        {/* Route when bidder bid  */}
                        <Route
                          path="/tender-bid/:id"
                          element={<BidDetailPage />}
                        />
                      </Route>
                      <Route path="/my-ebids" element={<MyBidsPage />} />
                      {/* Route to edit bid tender */}
                      <Route
                        path="/my-ebids/edit/:id"
                        element={<EditBidPage />}
                      />
                      <Route
                        path={`/bid-tender/esewa/payment_verification`}
                        element={<EsewaFeeVerification />}
                      />

                      <Route
                        path={"/bid-tender/khalti/payment_verification"}
                        element={<KhaltiBidFeeVerification />}
                      />
                      <Route path="/saved-bids" element={<SavedBids />} />
                    </Route>
                    {/* Routes for Publiusher */}
                    <Route element={<RequirePublishAuth />}>
                      <Route path="/bids" element={<HomePage />} />
                      <Route element={<EligibleForPublishorBid />}>
                        <Route
                          path="/publish-tender"
                          element={<PublishTenderStepper />}
                        />
                      </Route>
                      <Route element={<EligibleForPublishorBid />}>
                        <Route
                          path="/publish-e-notice"
                          element={<PublishENotice />}
                        />
                        <Route
                          path="/publish-e-notice/edit/:id"
                          element={<EditENotice />}
                        />
                      </Route>
                      <Route path="/pay" element={<PaymentPopUp />} />
                      {/* <Route path="my-tenders" element={<MyBidsPage />} /> */}
                      <Route
                        path="my-tenders/edit/:id"
                        element={<EditTenderPage />}
                      />
                      <Route
                        path="my-tenders/:id/enlistment/quotation"
                        element={<EnlistmentQuotation />}
                      />

                      <Route
                        path={`/publish-tender/esewa/payment_verification/`}
                        element={<EsewaFeeVerification />}
                      />
                      <Route
                        path={`/publish-tender/khalti/payment_verification`}
                        element={<KhaltiBidFeeVerification />}
                      />
                    </Route>
                    <Route path="/pricing-plan" element={<PricingPlan />} />
                    <Route path="/results" element={<ResultsGroup />} />
                    <Route path={"/contact"} element={<ContactUS />} />
                    <Route path="/community" element={<PettyPage />} />
                    <Route path="/interest/:id" element={<UserInterest />} />
                    <Route path="/register-form" element={<RegisterForm />} />
                    # About Company and Policies
                    <Route path="/about-us" element={<AboutUs />} />
                    <Route path="/privacy-policy" element={<CompanyPolicy />} />
                    <Route
                      path="/publisher-policy"
                      element={<PublisherPolicy />}
                    />
                    <Route path="/bidder-policy" element={<Bidder />} />
                    <Route path="/bidder-term" element={<BidderTerm />} />
                    <Route path="/mobile-view" element={<MobilePageCheck />} />
                    <Route
                      path="/request-account-deletion"
                      element={<AccountDeletion />}
                    />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                  <ToastContainer />
                  <Footer />
                </BrowserRouter>
              </SearchProvider>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Router;
