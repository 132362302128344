import { useEffect, useState } from "react";
import "./Procurement.scss";
import { getTenderAttrs } from "../Api/GetApi";
import { postUserInterest } from "../Api/PostApi";
import { toast } from "react-toastify";
import { CheckButton } from "../CheckButton/CheckButton";
import { CustomLoader } from "../Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { EditProcurement } from "../Api/PutApi";
import { useAuth } from "../Context/AuthContext";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

export interface CategoryInterface {
  value: string;
  label: string;
  proc_id: string;
}

export interface ITenderAttr {
  procurement_type: Array<CategoryInterface>;
  project_category: Array<CategoryInterface>;
}

export interface ProcurementProps {
  showEdit?: boolean;
  savedProject?: any;
  setEditOpen?: any;
}
export const Procurement: React.FC<ProcurementProps> = ({
  showEdit,
  savedProject,
  setEditOpen,
}) => {
  const { userProfile } = useAuth();
  const { id } = useParams();
  const [selectedProcurements, setSelectedProcurements] = useState<String[]>(
    []
  );
  const [selectedProjects, setSelectedProjects] = useState<Array<string>>([]);
  const [interestAttr, setInterestAttr] = useState<ITenderAttr>();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const fetchTenderAttr = () => {
    getTenderAttrs().then((res) => {
      setInterestAttr(res.data.data);
    });
  };

  useEffect(() => {
    fetchTenderAttr();
    if (savedProject) {
      const newArr = savedProject?.map((item: any) => {
        return item?.id;
      });

      setSelectedProjects(newArr);
    }
  }, []);

  useEffect(() => {
    interestAttr?.procurement_type?.map((item) => {
      return interestAttr?.project_category.filter(
        (itm) => item.value === itm?.proc_id
      );
    });
  }, [interestAttr?.procurement_type]);

  useEffect(() => {
    const tempArray: String[] = [...selectedProcurements];
    savedProject &&
      interestAttr?.project_category.map((item) => {
        const savedProjectIds = savedProject.map(
          (val: { name: string; id: number }) => {
            return val.id;
          }
        );
        if (savedProjectIds.includes(item.value)) {
          if (!tempArray.includes(item.proc_id)) {
            tempArray.push(item.proc_id);
          }
        }
      });
    setSelectedProcurements(tempArray);
  }, [savedProject, interestAttr]);

  const onsubmit = () => {
    setLoading(true);
    var postData = {
      user_id: id,
      procurement_types: selectedProcurements,
      project_categories: selectedProjects,
    };
    postUserInterest(postData)
      .then((res) => {
        toast.success(res.data.status.status_message);
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        toast.error(
          err.response.status,
          err.response.data.status.status_message
        );
        setLoading(false);
      });
  };

  const onEdit = () => {
    var postData = {
      user_id: id,
      procurement_types: selectedProcurements,
      project_categories: selectedProjects,
    };
    EditProcurement(postData)
      .then((res) => {
        toast.success(res.data.status.status_message);
        userProfile();
        setEditOpen(false);
      })
      .catch((err) => {
        toast.error(
          err.response.status,
          err.response.data.status.status_message
        );
        setLoading(false);
      });
  };

  // const filterItem = project_category?.map((item:any)=> item.name === )

  return (
    <div className="procurement">
      <div
        className="close-icon"
        onClick={() => {
          setEditOpen(false);
        }}
      >
        <Icon className=" mdi-close" path={mdiClose} />
      </div>
      <div className="title-block">
        <h4 className="title">Choose Your Interest</h4>
      </div>

      <div className="procurement-block">
        <div className={`interested-block`}>
          <h4 className="title mb-20">
            The procurement and Projects you are interested
          </h4>

          <div className="row">
            {interestAttr?.procurement_type.map((item, index) => {
              const projects = interestAttr?.project_category.filter(
                (itm) => itm?.proc_id === item.value
              );
              const newArr = projects?.map((s) => s.value);

              return (
                <div className="col-md-3">
                  <CheckButton
                    label={item.label}
                    id={item.value}
                    onClick={() => {
                      if (selectedProcurements?.includes(item?.value)) {
                        const temp = selectedProcurements?.filter(
                          (f) => f !== item?.value
                        );
                        setSelectedProcurements(temp);

                        const newTemp = selectedProjects?.filter(
                          (el) => !newArr.includes(el)
                        );

                        setSelectedProjects(newTemp);
                      } else {
                        setSelectedProcurements([
                          ...selectedProcurements,
                          item?.value,
                        ]);
                        const newTemp = projects?.map((s) => s.value);
                        setSelectedProjects([...selectedProjects, ...newTemp]);
                      }
                    }}
                    isChecked={newArr.every((x) =>
                      selectedProjects?.includes(x)
                    )}
                  />
                  {projects?.map((itm, idx) => {
                    const exists = selectedProjects?.find(
                      (p) => p === itm.value
                    );

                    return (
                      <div className="project-section">
                        <CheckButton
                          label={itm.label}
                          id={itm.value}
                          onClick={() => {
                            if (selectedProjects?.includes(itm?.value)) {
                              const temp = selectedProjects?.filter(
                                (f) => f !== itm?.value
                              );
                              setSelectedProjects(temp);
                              if (!newArr.some((x) => temp.includes(x))) {
                                const newTemp = selectedProcurements?.filter(
                                  (f) => f !== item?.value
                                );
                                setSelectedProcurements(newTemp);
                              }
                            } else {
                              setSelectedProjects([
                                ...selectedProjects,
                                itm?.value,
                              ]);

                              if (
                                selectedProcurements?.includes(itm?.proc_id)
                              ) {
                              } else {
                                setSelectedProcurements([
                                  ...selectedProcurements,
                                  itm?.proc_id,
                                ]);
                              }
                            }
                          }}
                          isChecked={Boolean(exists)}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>

        <div style={{ textAlign: "right" }}>
          {!showEdit ? (
            <button
              className="primary-btn"
              onClick={() => {
                onsubmit();
              }}
            >
              {loading ? (
                <CustomLoader
                  type="TailSpin"
                  color="#FFFFFF"
                  height={25}
                  width={52}
                />
              ) : (
                <span>Submit</span>
              )}
            </button>
          ) : (
            <button
              className="primary-btn"
              onClick={() => {
                onEdit();
              }}
            >
              <span>Update</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
