import React, { useState } from "react";
import ENoticeFormikWrapper, {
  PublishENoticeData,
} from "../FormikWrapper/ENoticeFormikWrapper";
import "./PublishENotice.scss";
import { NewENotice } from "../NewENotice/NewENotice";
import { publishTender } from "../Api/PostApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { catchError } from "../utils/catchError";
import { UpdateTenderNotice } from "../Api/PutApi";
export interface PublishENoticeProps {
  initialValuesProps?: PublishENoticeData;
}

const PublishENotice: React.FC<PublishENoticeProps> = ({
  initialValuesProps,
}) => {
  const [loading, setLoading] = useState(false);
  const [publishLoad, setPublishLoad] = useState(false);
  const navigate = useNavigate();

  window.scrollTo(0, 0);

  const PublishENotice = (values: PublishENoticeData, resetForm: Function) => {
    const cloned = {
      ...values,
      notice_category: values.notice_category?.value,
    };
    if (values.saved) {
      setLoading(true);
    } else {
      setPublishLoad(true);
    }

    const formData: any = new FormData();
    formData.append("payload", JSON.stringify(cloned));
    publishTender(formData)
      .then((res) => {
        if (res.status === 200) {
          if (values.saved) {
            navigate(`/?tab=4`);
          } else {
            navigate(`/?tab=2`);
          }
          toast.success(res.data.status.status_message);
          resetForm();
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        if (values.saved) {
          setLoading(false);
        } else {
          setPublishLoad(false);
        }
      });
  };

  const EditENotice = (
    values: PublishENoticeData,
    id: string | number | undefined
  ) => {
    if (values.saved) {
      setLoading(true);
    } else {
      setPublishLoad(true);
    }

    const cloned = {
      ...values,
      notice_category: values.notice_category?.value,
    };
    const formData: any = new FormData();

    formData.append("payload", JSON.stringify(cloned));
    UpdateTenderNotice(formData, id)
      .then((res) => {
        toast.success(res.data.status.status_message);
        if (values.saved) {
          navigate(`/?tab=4`);
        } else {
          navigate(`/?tab=2`);
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        if (values.saved) {
          setLoading(false);
        } else {
          setPublishLoad(false);
        }
      });
  };

  return (
    <>
      <div className="quotations-wrapper bids-page">
        <div className="container">
          <div className="box card">
            <div className="title-note">
              <h3 className="head-titile">
                {initialValuesProps ? "Edit your E-Notice" : "Publish E-Notice"}
              </h3>
            </div>

            <div className="form-section">
              <div className="W-100">
                <ENoticeFormikWrapper
                  PublishENotice={PublishENotice}
                  initialValuesProps={initialValuesProps}
                  EditENotice={EditENotice}
                >
                  <div className="Stepper">
                    <div className="card-section">
                      <NewENotice loading={loading} publishLoad={publishLoad} />
                    </div>
                  </div>
                </ENoticeFormikWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublishENotice;
