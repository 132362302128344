import React, { createContext, useContext, useEffect, useState } from "react";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { logout } from "../Api/DeleteApi";
import { getSampleFile, getUser, isAuth } from "../Api/GetApi";
import { login } from "../Api/PostApi";
import { catchError } from "../utils/catchError";
import "./AuthContext.scss";
import { ModalContext } from "./ModalContext/ModalContext";
export interface Authenticate {
  isAuthenticate: boolean;
}
export interface IsAuthenticate {
  isAuthenticate?: Authenticate;
  setIsAuthenticate: () => void;
}
interface Login {
  email: string;
  password: string;
}
type Children = {
  children: React.ReactNode; // 👈️ type children
};
export interface UserProfile {
  company_contact_no: string;
  company_name: string;
  contact_no: string;
  designation: string;
  district: string;
  email: string;
  first_name: string;
  last_name: string;
  liscense_back: string;
  liscense_front: string;
  middle_name: string;
  municipality: string;
  office_email: string;
  organization_category: string;
  pan_vat_certificate: string;
  province: string;
  registration_certificate_back: string;
  registration_certificate_front: string;
  username: string;
  website_url: string;
  is_verified?: boolean;
}

export interface SampleFiles {
  boq_catalog_file: string;
  power_of_attorney: string;
  bank_guarenty: string;
  registration_certificate_back: string;
  registration_certificate_front: string;
  bid_letter: string;
  declaration_letter: string;
  company_tax_clearence: string;
  liscense_back: string;
  liscense_front: string;
  id: number;
  pan_vat_certificate: string;
}

export const AuthContext = createContext<any>(null);
export const AuthProvider: React.FC<Children> = (props) => {
  const [isAuthenticate, setIsAuthenticate] = useState<boolean>(false);
  const [role, setRole] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [eligible, setEligible] = useState<boolean>(false);
  const [userData, setUserData] = useState<UserProfile>();
  const [sampleFile, setSampleFile] = useState<SampleFiles>();

  const { TogglePopUpFlags } = useContext(ModalContext);

  const IsAuthenticate = () => {
    setLoading(true);
    isAuth()
      .then((res) => {
        setIsAuthenticate(true);
        setRole(res.data.data.role);
        setEligible(res.data.data.eligible_for_bid);
        userProfile();
      })
      .finally(() => setLoading(false));
  };

  const LogIn = (
    data: Login,
    navigate: Function,
    location: string,
    loading: any,
    setLoading: any
  ) => {
    setLoading(true);
    login(data)
      .then((res) => {
        if (res.status === 200) {
          setIsAuthenticate(true);
          setRole(res.data.role);
          setEligible(res.data.eligible_for_bid);
          navigate(location);
          userProfile();
          TogglePopUpFlags("login");
        }
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const userProfile = async () => {
    try {
      const res = await getUser();
      setUserData(res.data.data);
    } catch (err) {
      toast.error("something went wrong");
    }
    return 0;
  };

  const logOut = async (navigate: NavigateFunction) => {
    try {
      const res = await logout();
      if (res.status === 200) {
        navigate("/");
        setIsAuthenticate(false);
        setRole("");
      }
    } catch (err) {}
  };
  // const FetchSampleFile = () => {
  //   getSampleFile()
  //     .then((res) => {
  //       setSampleFile(res.data.data[0]);
  //     })
  //     .catch((err) => {
  //       if (err) {
  //         toast.error("Something went wrong");
  //       }
  //     });
  // };

  useEffect(() => {
    IsAuthenticate();
    // FetchSampleFile();
  }, []);
  return (
    <>
      <AuthContext.Provider
        value={{
          IsAuthenticate,
          isAuthenticate,
          userProfile,
          userData,
          loading,
          setLoading,
          LogIn,
          role,
          logOut,
          sampleFile,
          eligible,
        }}
      >
        {props.children}
      </AuthContext.Provider>
    </>
  );
};

export const useAuth = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return "useAuth must be used with AuthContext";
  }
  return authContext;
};
export default AuthProvider;
