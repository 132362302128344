import "./StepperTwo.scss";
import { useFormikContext, Field, ErrorMessage } from "formik";
import { mdiAlertCircleOutline, mdiTrashCan } from "@mdi/js";
import { Icon } from "@mdi/react";
import "react-phone-input-2/lib/style.css";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { DropZoneBox } from "../pdfDropzone/pdfDropzone";
import { initialvalues } from "../FormikWrapper/RegisterFormikWrapper";
import { OrganizationCategory } from "../utils/OrganizationCategory";
import { NepaliDistricts } from "../../Datas/Districts";
import { Municipalities } from "../../Datas/Municipalities";
import { Provinces } from "../../Datas/Provinces";
import CustomSelect from "../SelectComponent/SelectComponent";
import { CustomLoader } from "../Loader/Loader";
import { Role } from "../../utils/EnumConstants";
export interface InputWithFlagProps {
  label?: string;
  phoneNumber?: string;
  setPhoneNumber?: (phoneNumber: string) => void;
  Page: number;
  setPage: Dispatch<SetStateAction<number>> | undefined;
  loading?: boolean;
  setSkipValue: Dispatch<SetStateAction<boolean>>;
  skipLoading?: boolean;
}

export const defaultSelectValues = {
  initialDistrict: {
    label: "Select District",
    value: "",
  },
  initialMunicipality: {
    label: "Select Municiplaity",
    value: "",
  },
  initialProvince: {
    label: "Select Province",
    value: "",
  },
};

export const OnbordingTwo: React.FC<InputWithFlagProps> = ({
  label,
  Page,
  setPage,
  loading,
  setSkipValue,
  skipLoading,
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<any>(null);
  const { values, setValues, setFieldValue } =
    useFormikContext<typeof initialvalues>();

  const handleImageChange = (e: any) => {
    if (e) {
      const previewUrl = URL.createObjectURL(e);
      setImagePreviewUrl(previewUrl);
    }
  };
  useEffect(() => {
    handleImageChange(values.logo);
  }, [values.logo]);

  const handleDelete = () => {
    setImagePreviewUrl(null);
    setFieldValue("logo", "");
  };

  const years = [];
  for (let year = 1900; year <= new Date().getFullYear(); year++) {
    years.push({ label: year, value: year });
  }

  return (
    <div className="outer-box">
      <div className="organizational-details">
        <div className="row">
          {values.role === Role.Publisher && (
            <>
              <div className="col-lg-3">
                {!imagePreviewUrl && (
                  <div className="custom-dropzone">
                    <label className="form-label">
                      Company Logo<span className="red">*</span>
                    </label>
                    <DropZoneBox
                      name="logo"
                      placeholder="Select Logo"
                      label="Company Logo *"
                    />
                  </div>
                )}
                {imagePreviewUrl && (
                  <>
                    <label className="form-label">
                      Company Logo<span className="red">*</span>
                    </label>
                    <div className="image-section">
                      <img src={imagePreviewUrl} alt="Company Logo" />
                      <div
                        className="close-icon"
                        title="Delete"
                        onClick={() => {
                          handleDelete();
                        }}
                      >
                        <Icon className=" mdi-trash" path={mdiTrashCan} />
                      </div>
                    </div>
                  </>
                )}
                <span className="error">
                  <ErrorMessage name="logo" />
                </span>
              </div>
              <div className="col-lg-9 col-sm-12">
                <div className="mg-bottom">
                  <label className="form-label">
                    About Company<span className="red">*</span>
                  </label>
                  <Field
                    name="introduction"
                    type="text"
                    placeholder="Enter introduction of company"
                    className="form-control about_company_textarea"
                    as="textarea"
                    rows="5"
                  />
                  <span className="error">
                    <ErrorMessage name="introduction" />
                  </span>
                </div>
              </div>
            </>
          )}

          <div className="col-lg-6 col-sm-12">
            <div className="mg-bottom">
              <label className="form-label">
                Company Name<span className="red">*</span>
              </label>
              <Field
                name="company_name"
                type="text"
                placeholder="Enter company name "
                className="form-control"
              />
              <span className="error">
                <ErrorMessage name="company_name" />
              </span>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="mg-bottom">
              <label className="form-label">Official Email</label>
              <Field
                name="office_email"
                type="text"
                placeholder="Enter official email "
                className="form-control"
              />
              <span className="error">
                <ErrorMessage name="office_email" />
              </span>
            </div>
          </div>

          {values.role === Role.Publisher && (
            <div className="col-xl-12 col-lg-12">
              <div className="mg-bottom">
                <label className="form-label">
                  Organization Category <span className="red">*</span>
                </label>
                <Field
                  as="select"
                  name="organization_category"
                  className="form-control"
                  aria-label=".form-select-sm example"
                >
                  <option value="" selected>
                    Choose Organization Category
                  </option>
                  {OrganizationCategory.map((item, id) => {
                    return (
                      <>
                        <option key={id} value={item.value}>
                          {item.label}
                        </option>
                      </>
                    );
                  })}
                </Field>

                <span className="error">
                  <ErrorMessage name="organization_category" />
                </span>
              </div>
            </div>
          )}
          {values.role === Role.Publisher && (
            <>
              <div className="col-lg-6 col-sm-12">
                <div className="mg-bottom">
                  <label className="form-label">
                    Year of Establishment<span className="red">*</span>
                  </label>
                  <Field
                    className="abc"
                    name="estd"
                    options={years}
                    component={CustomSelect}
                    placeholder="Select established year"
                    isMulti={false}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="mg-bottom">
                  <label className="form-label">
                    Number of Employees<span className="red">*</span>
                  </label>
                  <Field
                    name="employee_no"
                    type="text"
                    placeholder="Enter number of employees"
                    className="form-control"
                  />
                </div>
              </div>
            </>
          )}
          <div className="col-lg-6 col-sm-12">
            <div className="mg-bottom">
              <label className="form-label">
                Contact Number{" "}
                <span className="font-text">
                  (This number will be used for OTP Confirmation)
                </span>
              </label>

              <div className="input-with-flag">
                {label && <span className="label">{label}</span>}
                <div className="input-with-falg-block">
                  <PhoneInput
                    country={"np"}
                    value={values.company_contact_no}
                    onChange={(value) => {
                      setValues({
                        ...values,
                        company_contact_no: value,
                      });
                    }}
                  />
                  <span className="error">
                    <ErrorMessage name="company_contact_no" />
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-sm-12">
            <div className="mg-bottom">
              <label className="form-label">Website URL</label>
              <Field
                name="website_url"
                type="text"
                placeholder="Enter website URL"
                className="form-control"
              />
              <span className="error">
                <ErrorMessage name="website_url" />
              </span>
            </div>
          </div>

          <div className="col-xl-4 col-lg-12">
            <div className="mg-bottom">
              <label className="form-label">
                Province<span className="red">*</span>
              </label>
              <Field
                className="abc"
                name="province"
                options={Provinces}
                component={CustomSelect}
                placeholder="Select a province"
                isMulti={false}
              />

              <span className="error">
                <ErrorMessage name="province" />
              </span>
            </div>
          </div>
          <div className="col-xl-4 col-lg-12">
            <label className="form-label">
              District<span className="red">*</span>
            </label>
            <Field
              className="abc"
              name="district"
              options={NepaliDistricts}
              component={CustomSelect}
              placeholder="Select a district"
              isMulti={false}
            />

            <span className="error">
              <ErrorMessage name="district" />
            </span>
          </div>

          <div className="col-xl-4 col-lg-12">
            <label className="form-label">
              Municipality<span className="red">*</span>
            </label>
            <Field
              className="abc"
              name="municipality"
              options={Municipalities}
              component={CustomSelect}
              placeholder="Select a municipality"
              isMulti={false}
            />

            <span className="error">
              <ErrorMessage name="municipality" />
            </span>
          </div>
          <div className="col-xl-12 col-lg-12">
            <div className="uploadDocument">
              {" "}
              Upload Document
              <div className="success-box">
                <div>
                  <Icon
                    className="mdi-alert"
                    path={mdiAlertCircleOutline}
                  ></Icon>
                </div>
                <p className="font-text">Upload pdf file only</p>
              </div>{" "}
            </div>
          </div>

          <div
            className="col-md-12
         "
          >
            <DropZoneBox
              name="pan_vat_certificate"
              placeholder="Upload PAN/VAT certificate *"
              label="PAN / VAT Certificate *"
            />
            <span className="error">
              <ErrorMessage name="pan_vat_certificate" />
            </span>
          </div>
          <div className="col-md-6">
            <DropZoneBox
              name="registration_certificate_front"
              placeholder="Upload Company Registration Certificate (Front) *"
              label="Company Registration Certificate (Front)*"
            />

            <span className="error">
              <ErrorMessage name="registration_certificate_front" />
            </span>
          </div>

          <div className="col-md-6">
            <DropZoneBox
              name="registration_certificate_back"
              placeholder="Upload Company Registration Certificate (Back) "
              label="Company Registration Certificate (Back) *"
            />
            <span className="error">
              <ErrorMessage name="registration_certificate_back" />
            </span>
          </div>

          <div className="col-md-6">
            <DropZoneBox
              name="liscense_front"
              placeholder="Upload License (Front) "
              label="License (Front)"
            />
            <span className="error">
              <ErrorMessage name="liscense_front" />
            </span>
          </div>

          <div className="col-md-6">
            <DropZoneBox
              name="liscense_back"
              placeholder="Upload License (Back) "
              label="License (Back)"
            />
            <span className="error">
              <ErrorMessage name="liscense_back" />
            </span>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="line-section primary-section">
          <button
            className="btn primary-btn skip-btn"
            type={Page === 2 ? "submit" : "button"}
            disabled={skipLoading}
            onClick={() => {
              setSkipValue(true);
              setFieldValue("skip", "true");
            }}
          >
            {skipLoading ? (
              <CustomLoader
                type="TailSpin"
                color="#FFFFFF"
                height={25}
                width={52}
              />
            ) : (
              <span>Skip & Register</span>
            )}
          </button>
          <button
            className="btn primary-btn"
            type={Page === 2 ? "submit" : "button"}
            disabled={loading}
            onClick={() => {
              setSkipValue(false);
              setFieldValue("skip", "false");
            }}
          >
            {loading ? (
              <CustomLoader
                type="TailSpin"
                color="#FFFFFF"
                height={25}
                width={52}
              />
            ) : (
              <span>Register</span>
            )}
          </button>
        </div>
      </div>
      <div className="back-btn ">
        <div className="line-section primary-section">
          <button
            className="btn primary-btn"
            type="button"
            onClick={() => setPage?.(1)}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};
