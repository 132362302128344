import { Form, Formik, useFormikContext } from "formik";
import React from "react";
import * as Yup from "yup";
import { addDaysInDate } from "../utils/generic";
import { useParams } from "react-router-dom";

export interface PublishENoticeData {
  title: string;
  notice_category: { label: string; value: number } | null;
  project_category: [];
  work_locations: Array<string>;
  publishing_date: string;
  submission_date: string;
  notes: string;
  notice_type: string;
  saved: boolean;
}

interface ENoticeFormikWrapperProps {
  children: any;
  PublishENotice: (values: PublishENoticeData, resetForm: Function) => void;
  initialValuesProps?: PublishENoticeData;
  EditENotice?: (values: PublishENoticeData, id: string) => void;
}

const currentDate = new Date();
currentDate.setDate(currentDate.getDate());
const defaultPublishingDate = currentDate.toISOString().split("T")[0];
const defaultSubmissionDate = addDaysInDate(defaultPublishingDate, 5);

export const initialValues: PublishENoticeData = {
  title: "",
  notice_category: null,
  project_category: [],
  work_locations: [],
  publishing_date: defaultPublishingDate,
  submission_date: defaultSubmissionDate,
  notes: "",
  notice_type: "E-Notice",
  saved: false,
};

export const useENoticeFormik = () => {
  const formik = useFormikContext<PublishENoticeData>();
  if (!formik) {
    throw new Error(
      "useENoticeFormik must be used within a ENoticeFormikWrapper"
    );
  }
  return formik;
};

const ENoticeFormikWrapper: React.FC<ENoticeFormikWrapperProps> = ({
  children,
  PublishENotice,
  initialValuesProps,
  EditENotice,
}) => {
  const { id } = useParams();
  const validatePublishingDate = () => {
    const today = new Date();
    today.setDate(today.getDate());
    if (initialValuesProps) {
      const requireDate = new Date(initialValuesProps.publishing_date);
      const validationDate = requireDate.toLocaleDateString();
      return validationDate;
    }
    return today.toLocaleDateString();
  };

  let validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    work_locations: Yup.array()
      .of(Yup.string().required("Required")) 
      .min(1, "Please choose at least one location"),
    notes: Yup.string().required("Required"),
    notice_category: Yup.object()
      .shape({
        value: Yup.number().required("Required"),
        label: Yup.string().required("Required"),
      })
      .nullable()
      .required("Required"),

    project_category: Yup.array()
      .min(1, "Required")
      .max(1, "Please choose only one option"),

    publishing_date: Yup.date()
      .min(
        validatePublishingDate(),
        `Publishing date must be greater or equal to ${validatePublishingDate()}`
      )
      .required("Required"),

    submission_date: Yup.date()
      .min(
        Yup.ref("publishing_date"),
        "Submission date cannot be before publishing date"
      )
      .test(
        "is-submission-date-valid",
        "Submission date must be at least 5 days after publishing date",
        function (value) {
          const publishingDate = this.resolve(Yup.ref("publishing_date"));
          const minSubmissionDate = new Date(publishingDate as string);
          minSubmissionDate.setDate(minSubmissionDate.getDate() + 5);
          return (value as Date) >= minSubmissionDate;
        }
      )
      .required("Submission date is required"),
  });

  return (
    <>
      <Formik
        validateOnChange={true}
        initialValues={initialValuesProps ? initialValuesProps : initialValues}
        onSubmit={(values, { resetForm }) => {
          if (initialValuesProps) {
            EditENotice?.(values, id || "");
          } else {
            PublishENotice(values, resetForm);
          }
        }}
        validationSchema={validationSchema}
      >
        <Form>{children}</Form>
      </Formik>
    </>
  );
};

export default ENoticeFormikWrapper;
