import Axios from "../Api/AxiosInstance";
import { SearchQuery } from "../Context/SearchContext";

export const getUser = () => {
  return Axios.get("/user");
};
export const isAuth = () => {
  return Axios.get("/user_session");
};

export const getPetty = () => {
  return Axios.get("/pettys");
};

export const getTenderAttrs = () => {
  return Axios.get("/tender_attribute");
};

export const getAllTenderNotice = (
  page: number,
  query: SearchQuery,
  isPrivateOnly?: boolean, 
  notice_type?: string
) => {
  return Axios.get(
    `/tender_notice?page=${page}&district=${query.location}&project_category=${query.project_category}&procurement_type=${query.procurement_type}&notice_category=${query.notice_category}&organization_category=${query.organization_category}&title=${query.title}&publishing_date=${query.date}&budget=${query.budget}&is_private_only=${isPrivateOnly}&notice_type=${notice_type}`
  );
};
export const getSpecificTender = (id: any, oldNotice: boolean) => {
  return Axios.get(`/tender_notice/${id}?old_notice=${oldNotice}`);
};
export const getViewTender = (id: string | undefined) => {
  return Axios.get(`/tender_bid/${id}`);
};

export const getTenderResult = (page: number, query: SearchQuery) => {
  return Axios.get(
    `/tender_result?page=${page}&district=${query.location}&project_category=${query.project_category}&procurement_type=${query.procurement_type}&notice_category=${query.notice_category}&organization_category=${query.organization_category}&title=${query.title}&publishing_date=${query.date}`
  );
};

export const getMyBids = (id?: string) => {
  if(id) {
    return Axios.get(`/my_bid?publisher_id=${id}`);
  }
  else {
    return Axios.get(`/my_bid`);
  }
};

export const getBankDetail = () => {
  return Axios.get("/publisher_bank_detail");
};
export const getEmailVerfied = (id: string | undefined) => {
  return Axios.get(`/user/registration_verification/${id}/`);
};
export interface BodyDataBidder {
  tender_notice_id: number | string | undefined;
  bidder_id: number | string | undefined;
}

export const getBidderDetail = (data: BodyDataBidder) => {
  return Axios.get(
    `/bidder_bid_detail?bidder_id=${data?.bidder_id}&tender_notice_id=${data?.tender_notice_id}`
  );
};

export const getSampleFile = () => {
  return Axios.get("/document_sample_file");
};

export const testimonailsData = () => {
  return Axios.get("/testimonials");
};

export const GetClientData = () => {
  return Axios.get("/our-clients");
};

export const getSavedTenderBids = () => {
  return Axios.get("/user_saved_tender_notices");
};

export const getAllAdvertisements = () => {
  return Axios.get("/advertisements");
};

export const getDistricts = () => {
  return Axios.get("/districts");
};

export const getPricingPlan = () => {
  return Axios.get("/plans");
};

export const getNotification = () => {
  return Axios.get("/notifications");
};

export const getBiddersList = (id: string | undefined) => {
  return Axios.get(`/tender_bid/technical?tender_notice_id=${id}`);
};

export const getPublisherData = (id: string) => {
  return Axios.get(`/user/${id}/personal_detail`);
};