import { useContext, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { saveTenderBid } from "../Api/PostApi";
import { BOqModel } from "../BoqModel/BoqModel";
import { useAuth } from "../Context/AuthContext";
import { ModalContext } from "../Context/ModalContext/ModalContext";
import { ImagePopup } from "../ImagePopup/ImagePopup";
import { catchError } from "../utils/catchError";
import "./TenderCardAction.scss";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import { TenderCardAd } from "../TenderCard/TenderCardAd";
import { useGlobal } from "../Context/GlobalContext";
import { isBidder } from "../../utils/services";
import { AdditionalAttributes } from "../FormikWrapper/TenderFormikWrapper";
export interface actionProps {
  shareUrl: any;
  boq: any;
  tender_id?: number;
  savebids?: boolean;
  title?: string;
  image?: string;
  ppmoFileUrl?: string;
  advertisement?: any;
  notes?: string;
  terms?: string;
  show_boq_item_rate?: boolean;
  opening_date?: string;
  category_type?: string;
  handleOnClickBid?: (tenderId: number) => void;
  remaining_days?: number;
  privateBid?: boolean;
  additional_attributes?: AdditionalAttributes[];
}
export interface Props {
  toggleModal?: () => void;
}
export const TenderCardAction: React.FC<actionProps> = ({
  shareUrl,
  boq,
  tender_id,
  savebids,
  title,
  image,
  ppmoFileUrl,
  notes,
  terms,
  show_boq_item_rate,
  opening_date,
  category_type,
  handleOnClickBid,
  remaining_days,
  privateBid,
  additional_attributes,
}) => {
  const { role, isAuthenticate, userData } = useAuth();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [accept, setAccept] = useState(false);
  const { TogglePopUpFlags } = useContext(ModalContext);
  const { advertisements } = useGlobal();

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const boqstyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      overflow: "scroll",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "75%",
      border: "none",
      zIndex: 50,
      padding: "20px",
      height: "100%",
    },
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      overflow: "scroll",
      bottom: "auto",
      marginRight: "-50%",
      marginTop: "10px",
      height: "100%",
      transform: "translate(-50%, -50%)",
      width: ppmoFileUrl ? "600px" : "800px",
      border: "none",
      zIndex: 50,
      padding: "20px",
    },
  };

  const saveBids = (tender_id: string | number | undefined) => {
    saveTenderBid(tender_id)
      .then((res) => {
        toast.success("Bid Saved Successfully");
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      });
  };

  return (
    <div className="tender-action-section">
      <a title="Copy Link">
        <img
          className="btn-icon-size"
          src={require("../../images/copy.svg").default}
          alt="copy"
          onClick={() => {
            navigator.clipboard.writeText(
              `${window.location.origin}/${shareUrl}`
            );
            toast.info("Copied to clipboard");
          }}
        />
      </a>
      <a title="Document">
        <img
          onClick={() => {
            if (isAuthenticate || privateBid) {
              if (
                isBidder(role) &&
                userData?.plan_remaining_days <= 0 &&
                !privateBid
              ) {
                navigate("/pricing-plan");
              } else {
                toggleModal();
                setAccept(!accept);
              }
            } else {
              TogglePopUpFlags("login");
            }
          }}
          className="btn-icon-size"
          src={require("../../images/document_fill.svg").default}
          alt="images"
        />
      </a>

      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        style={boq?.boq_details ? boqstyles : customStyles}
      >
        <div
          className="modal-close-icon"
          onClick={() => {
            toggleModal();
          }}
        >
          <div className="modal-title popup-title">
            {boq?.boq_details ? "BOQ TABLE" : ""}
          </div>
          <Icon className=" mdi-close" path={mdiClose} />
        </div>

        <div style={{ marginBottom: "10px" }}>
          <TenderCardAd
            advertisement={
              advertisements && advertisements["Notice-PopUp"]?.[0]
            }
          />
        </div>

        {boq?.boq_details ? (
          <BOqModel
            toggleModal={() => toggleModal()}
            boq={boq}
            role={role}
            savedBids={saveBids}
            tender_id={tender_id}
            savebidbtn={savebids}
            title={title}
            notes={notes}
            terms={terms}
            show_boq_item_rate={show_boq_item_rate}
            remaining_days={remaining_days}
            handleOnClickBid={handleOnClickBid}
            privateBid={privateBid}
            additional_attributes={additional_attributes}
            hideBoqSumTable={true}
          />
        ) : (
          <ImagePopup
            ppmoFileUrl={ppmoFileUrl}
            toggleModal={toggleModal}
            savebids={savebids}
            image={image}
            saveBids={saveBids}
            tender_id={tender_id}
            role={role}
          />
        )}
      </Modal>
    </div>
  );
};
