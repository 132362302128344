import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
  getAllTenderNotice,
  getNotification,
} from "../../Components/Api/GetApi";
import { useAuth } from "../../Components/Context/AuthContext";
import { useSearch } from "../../Components/Context/SearchContext";
import { EBidPage } from "../../Components/EBids/EBids";
import { ENoticePage } from "../../Components/ENotice/ENotice";
import { ETenderSide } from "../../Components/ETenderSide/ETenderSide";
import { GalleryView } from "../../Components/GalleryView/GalleryView";
import { ItWorks } from "../../Components/ItWorks/ItWorks";
import NewBanner from "../../Components/NewBanner/NewBanner";
import { OurClients } from "../../Components/OurClients/OurClients";
import {
  CardView,
  TenderCardGroup,
} from "../../Components/TenderCard/TenderCardGroup";
import { Testimonials } from "../../Components/Testimonials/Testimonails";
import { catchError } from "../../Components/utils/catchError";
import "./HomePage.scss";
import { AdShow } from "../../Components/AdShow/AdShow";

export const HomePage = () => {
  const { isAuthenticate } = useAuth();
  const [showButton, setShowButton] = useState(false);
  const [viewType, setViewType] = useState("list");
  const [tabIndex, setTabIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [showCount, setShowCount] = useState(true);
  const [eBidloading, setEBidLoading] = useState<Boolean>(true);
  const [eNoticeloading, setENoticeLoading] = useState<Boolean>(true);
  const { query, page } = useSearch();
  const [eBidTenderCard, setEBidTenderCard] = useState<any>([]);
  const [eNoticeTenderCard, setENoticeTenderCard] = useState<any>([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200 && !showButton) {
        setShowButton(true);
        return;
      }
      if (window.scrollY === 0) {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const getView = (value: any) => {
    setViewType(value);
  };

  const today = new Date();
  const currentDate = today.toLocaleDateString("en-CA");
  const temp = localStorage.getItem("notification");
  const tempNotification = temp && JSON?.parse(temp as string);

  const onTabClick = () => {
    const notification = {
      date: currentDate,
      count: count,
    };
    if (tempNotification?.date !== currentDate || !tempNotification) {
      return localStorage.setItem(
        "notification",
        JSON?.stringify(notification)
      );
    } else {
      return temp;
    }
  };

  useEffect(() => {
    if (tempNotification?.date === currentDate) {
      setShowCount(false);
    } else setShowCount(true);
  }, [tempNotification]);

  useEffect(() => {
    getNotification().then((res) => {
      setCount(res?.data?.data?.["e-bids"]);
    });
  }, []);

  const handleEBidsViewAll = (): void => {
    if (setTabIndex) {
      setTabIndex(1);
    }
    if (onTabClick) {
      onTabClick();
    }
  };

  const handleENoticeViewAll = (): void => {
    if (setTabIndex) {
      setTabIndex(2);
    }
  };

  const getTenders = (page: number) => {
    setEBidLoading(true);
    return getAllTenderNotice(page, query, true)
      .then((res) => {
        setEBidTenderCard(res.data.data.results);
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => setEBidLoading(false));
  };

  const combinedEBidData = useMemo(() => {
    return eBidTenderCard.flatMap((item: any) => item.data);
  }, [eBidTenderCard]);

  const combinedENoticeData = useMemo(() => {
    return eNoticeTenderCard.flatMap((item: any) => item.data);
  }, [eNoticeTenderCard]);

  const getENotice = (page: number, notice_type: string) => {
    setENoticeLoading(true);
    return getAllTenderNotice(page, query, true, notice_type)
      .then((res) => {
        setENoticeTenderCard(res.data.data.results);
      })
      .catch((err) => {
        catchError(
          err.response.status,
          err.response.data.status.status_message
        );
      })
      .finally(() => setENoticeLoading(false));
  };

  useEffect(() => {
    getTenders(page);
    getENotice(page, "E-Notice");
  }, [page, query, isAuthenticate]);

  useEffect(() => {
    const tabValue = searchParams.get("tab");
    if (tabValue) {
      setTabIndex(Number(tabValue));
    }
  }, [searchParams]);

  return (
    <div className="home-page">
      {showButton && (
        <img
          className={`scroll-top-btn`}
          src={require("../../images/scroll-top.svg").default}
          alt="scroll-to-top"
          onClick={handleClick}
        />
      )}
      <NewBanner />
      <div className="card-wrapper-content">
        <div className="container">
          <div className="row">
            <div className="col-md-9 ">
              <div className="mg-top-bid"></div>
              <div className="main-tab">
                <div className="switch-btns">
                  {tabIndex === 0 && <GalleryView getView={getView} />}
                </div>
                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(index) => setTabIndex(index)}
                >
                  <TabList>
                    <Tab>All Bids </Tab>
                    <Tab>
                      <div
                        className="tab-head"
                        onClick={() => {
                          onTabClick();
                        }}
                      >
                        <div>
                          <span style={{ color: "#b61e25" }}>E</span>-Bids
                        </div>
                        {showCount && count > 0 && (
                          <div className="count">{count}</div>
                        )}
                      </div>
                    </Tab>
                    <Tab>
                      <div className="tab-head">
                        <div>
                          <span style={{ color: "#b61e25" }}>E</span>-Notice
                        </div>
                      </div>
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <TenderCardGroup viewType={viewType as CardView} />
                  </TabPanel>
                  <TabPanel>
                    <EBidPage />
                  </TabPanel>
                  <TabPanel>
                    <ENoticePage />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div className="col-md-3">
              <div className="mg-top-bid"></div>
              <div className="home-sidebar">
                <div className="mb-4">
                  <ETenderSide
                    loading={eBidloading}
                    handleViewAll={handleEBidsViewAll}
                    sectionTitle={"E-bids"}
                    tenderCard={combinedEBidData}
                    theme="Secondary"
                  />
                </div>
                <div className="mb-4">
                  <ETenderSide
                    loading={eNoticeloading}
                    handleViewAll={handleENoticeViewAll}
                    sectionTitle={"E-Notices"}
                    tenderCard={combinedENoticeData}
                    noticeType={"E-Notice"}
                    theme="Primary"
                  />
                </div>
                <div className="sidebar-sticky">
                  <AdShow />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ItWorks />
      <Testimonials />
      <OurClients />
    </div>
  );
};
