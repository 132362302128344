import { useContext } from "react";
import { useAuth } from "../../Components/Context/AuthContext";
import { TenderCardProps } from "../../Components/TenderCard/TenderCard";
import { TenderCardAction } from "../../Components/TenderCardAction/TenderCardAction";
import "./Results.scss";
import { isBidder } from "../../utils/services";
import { ModalContext } from "../../Components/Context/ModalContext/ModalContext";
import { ResponseTenderCard } from "../../Components/TenderCard/TenderCardGroup";
import { nepaliAmountFormat } from "nepali-number";

export interface Award {
  company: string;
  price: number;
  tender_number: string;
  awarded: boolean;
}
export interface ResultProps extends TenderCardProps {
  dataValue?: ResponseTenderCard;
}

export const Results: React.FC<ResultProps> = ({
  dataValue,
  ignore,
  setIgnore,
  savebids,
}) => {
  const shareUrl = `${
    dataValue?.private
      ? `bids/view/${dataValue?.id}`
      : `tender-notice/${dataValue?.id}`
  }`;
  const { TogglePopUpFlags } = useContext(ModalContext);
  const { role, isAuthenticate, userData } = useAuth();

  const navigateToNoticeDetail = (tender_id: string | number | undefined) => {
    if (!tender_id) return;
    // navigate(shareUrl);
    window.open(shareUrl, "_blank");
  };

  const handleOnClickTitle = () => {
    if (isAuthenticate === true) {
      if (isBidder(role) && userData?.plan_remaining_days === 0) {
        var planUrl = `${window.location.origin}/pricing-plan`;
        window.open(planUrl, "_blank");
      } else {
        navigateToNoticeDetail(dataValue?.id);
      }
    } else {
      TogglePopUpFlags("login");
    }
  };
  return (
    <div className="result-wrapper">
      <div className="card result-card">
        <div className="card-section">
          <div className="tid-content">
            <div className="tender-header">
              <div className="tag-info">
                <img
                  className="map-icon"
                  src={require("../../images/location_fill.svg").default}
                  alt="images"
                />
                <p className="tender-contents">
                  <span className="tender-bold">{dataValue?.district}</span>
                </p>
              </div>

              <div className="border-line"></div>
              <div className="tag-info">
                <p className="tender-contents">
                  <span className="tender-bold">
                    {dataValue?.notice_category}
                  </span>
                </p>
              </div>

              <div className="border-line"></div>
              <div className="tag-info">
                <p className="tender-contents">
                  <span className="tender-bold">{dataValue?.source}</span>
                </p>
              </div>
            </div>
            <div className="tid-contain-area">
              <p className="tid-no">
                TID :{" "}
                <span className="tender-bold">{dataValue?.tender_number}</span>
              </p>

              <TenderCardAction
                shareUrl={shareUrl}
                boq={dataValue?.boq}
                tender_id={dataValue?.id}
                savebids={savebids}
                title={dataValue?.title}
                image={dataValue?.image}
                ppmoFileUrl={dataValue?.ppmo_file_url}
                privateBid={dataValue?.private}
                additional_attributes={dataValue?.additional_attributes}
              />
            </div>
          </div>
          <div>
            <p
              className="tender-title"
              onClick={() => {
                handleOnClickTitle();
              }}
            >
              {dataValue?.title}
            </p>
          </div>
          <div>
            <p className="sub-title">{dataValue?.public_entity_name}</p>
          </div>
          <div className="tender-border"></div>
          <div className="card-wrapper">
            <div className="d-flex">
              <div className="bid-before padd-15">
                <div className="tag-info">
                  <img src={require("../../images/bid.png")} alt="images" />
                  <p className="tender-contents">
                    Published Date:
                    <span className="tender-bold">
                      {dataValue?.publishing_date}
                    </span>
                  </p>
                  {dataValue?.remaining_days !== null && (
                    <>
                      <div className="border-line"></div>
                      <p className="tender-contents">
                        Apply Before:
                        <span className="tender-bold">
                          {dataValue?.submission_date?.slice(0, 10)}
                        </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* <ShareButton size={24} shareUrl={shareUrl} /> */}
          </div>
          <div className="tender-border"></div>

          <div className="award-img">
            <img src={require("../../images/award.png")} alt="images" />
            <span className="awarded-header"> Awarded to: </span>
          </div>
          <div>
            <ul className="award-section">
              {dataValue?.award_result.map((award: any) => {
                return (
                  <li className="award-content">
                    {`Tender ID: ${award.tender_number ?? "-"}, ` +
                      (award.company ||= "N/A") +
                      `(Rs. ${
                        award.price ? nepaliAmountFormat(award.price) : "N/A"
                      })`}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
