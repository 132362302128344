import { faDownload, faPrint, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nepaliAmountFormat } from "nepali-number";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import { Role } from "../../utils/EnumConstants";
import { isBidder } from "../../utils/services";
import { postPpmoFileDetails } from "../Api/PostApi";
import { OrganizationDetail } from "../BidderDetail/BidderDetail";
import "../BidderDetail/BidderDetails.scss";
import { CompanyDetails } from "../BidderDetail/CompanyDetails";
import { useAuth } from "../Context/AuthContext";
import { inWords } from "../utils/NumbertoWords";
import { catchError } from "../utils/catchError";
import "./BoqModel.scss";
import { AdditionalAttributes } from "../FormikWrapper/TenderFormikWrapper";
import { ResponseBoq } from "../utils/interface";

interface BoqResponse {
  boq_details: Array<any>;
  boq_sum: number;
  vat_amount: number;
  additional_column_names?: string[];
}
export interface Props {
  toggleModal?: any;
  boq?: BoqResponse | ResponseBoq;
  role?: string;
  savedBids?: (tenderId: string | number) => void;
  tender_id?: number;
  savebidbtn?: boolean;
  title?: string;
  notes?: string;
  terms?: string;
  show_boq_item_rate?: boolean;
  remaining_days?: number;
  handleOnClickBid?: (tenderId: number) => void;
  privateBid?: boolean;
  organizationDetail?: OrganizationDetail;
  additional_attributes?: AdditionalAttributes[];
  hideNoteTerms?: boolean;
  hideBoqSumTable?: boolean;
}

export const BOqModel: React.FC<Props> = ({
  toggleModal,
  boq,
  role = Role.Bidder,
  savedBids,
  tender_id,
  savebidbtn,
  title,
  notes,
  terms,
  show_boq_item_rate,
  remaining_days,
  handleOnClickBid,
  privateBid,
  organizationDetail,
  additional_attributes,
  hideNoteTerms = false,
  hideBoqSumTable = false,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const { isAuthenticate } = useAuth();
  const handleDownload = () => {
    var boq_data = {
      boq_details: boq?.boq_details,
      boq_sum: boq?.boq_sum,
      vat_amount: boq?.vat_amount,
      grand_total: boq ? boq?.boq_sum + boq?.vat_amount : "N/A",
      notes: notes,
      terms: terms,
      tender_id: tender_id,
      show_boq_item_rate: show_boq_item_rate,
      additional_columns: boq?.additional_column_names,
    };
    postPpmoFileDetails(boq_data)
      //@ts-ignore
      .then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(file);
        link.download = "bolpatranepal-boq.pdf";
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        catchError(
          err.response?.status,
          err.response?.data.status.status_message
        );
      })
      .finally(() => {
        // setSubmitting(false);
      });
  };

  return (
    <>
      <div className="boq-top-model">
        <div>
          {boq ? (
            <div className="boq-wrapper abc">
              <div className="boq-bill">
                <div className="boq-popup-wrap-section">
                  <div ref={componentRef}>
                    <div className="letter-head-block">
                      <img
                        width={300}
                        src={require("../../images/bolpatra logo-full.png")}
                        alt="letter-head"
                      />
                    </div>
                    <p className="popup-sub-title">{title}</p>
                    {organizationDetail && (
                      <div className="printSection">
                        <div className="boq-title boq-topic">
                          <b>BIDDER DETAILS</b>{" "}
                        </div>
                        <CompanyDetails
                          organizationDetail={organizationDetail}
                        />
                      </div>
                    )}
                    <div className="boq-title boq-topic">BOQ TABLE </div>

                    <div className="scroll">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr className="table-header">
                            <th className="category">Category</th>
                            <th className="s-no">S.No.</th>
                            <th className="item-desc">Item/Work Description</th>
                            {additional_attributes &&
                              additional_attributes.map((column, index) => (
                                <th
                                  key={`${column.column}${index}`}
                                  className="quantity"
                                >
                                  {column.column}
                                </th>
                              ))}
                            <th className="unit">Unit</th>
                            <th className="quantity">Quantity</th>
                            {show_boq_item_rate && (
                              <>
                                <th className="rate">Rate(NPR)</th>
                                <th className="amount">Amount</th>
                              </>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {boq?.boq_details?.map((val: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                {val.item.map((item: any, id: number) => {
                                  return (
                                    <tr key={id}>
                                      {id === 0 && (
                                        <td
                                          className="inputfield-item vertical-content"
                                          rowSpan={val.item.length}
                                        >
                                          {val.category}
                                        </td>
                                      )}
                                      <td className="text-center inputfield-item">
                                        {id + 1}
                                      </td>
                                      <td>
                                        <div className="scroll input-text inputfield-item">
                                          {item.item_description}
                                        </div>
                                      </td>

                                      {item?.additional_attributes &&
                                        Object.keys(item?.additional_attributes)
                                          .length > 0 &&
                                        Object.keys(
                                          item?.additional_attributes
                                        )?.map((column, index) => (
                                          <td
                                            key={`${column}${index}`}
                                            className="text-center inputfield-item"
                                          >
                                            {item?.additional_attributes?.[
                                              column
                                            ] ?? ""}
                                          </td>
                                        ))}
                                      <td className="text-center inputfield-item">
                                        {item.unit}
                                      </td>

                                      <td className="text-center inputfield-item">
                                        {item.quantity}
                                      </td>
                                      {show_boq_item_rate && (
                                        <>
                                          <td className="text-center inputfield-item">
                                            {item.blank ? (
                                              "N/A"
                                            ) : (
                                              <>
                                                {nepaliAmountFormat(item.rate)}
                                                <p className="sub-item">
                                                  {inWords(
                                                    item.rate
                                                  )?.toUpperCase()}
                                                </p>
                                              </>
                                            )}
                                          </td>

                                          <td className="text-center inputfield-item">
                                            {item.blank ? (
                                              "N/A"
                                            ) : (
                                              <>
                                                {nepaliAmountFormat(
                                                  item.amount
                                                )}
                                                <p className="sub-item">
                                                  {inWords(
                                                    item.amount
                                                  )?.toUpperCase()}
                                                </p>
                                              </>
                                            )}
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {!hideBoqSumTable && (
                      <table className="w-100 table-striped">
                        <tbody>
                          <tr>
                            <td>
                              <div className="rate-flex">
                                <span className="total">BOQ Total</span>

                                <div className="amount-section">
                                  <span className="inputfield-item">NPR</span>
                                  <div className="rate-amount">
                                    {nepaliAmountFormat(boq.boq_sum)}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="rate-flex">
                                <span className="total">
                                  VAT <span> [13%]</span>
                                </span>
                                <div className="amount-section">
                                  <span className="inputfield-item">NPR</span>

                                  <div className="rate-amount">
                                    {nepaliAmountFormat(boq.vat_amount)}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="rate-flex">
                                <span className="total">Grand Total</span>
                                <div className="amount-section">
                                  <span className="inputfield-item">NPR</span>
                                  <div className="rate-amount ">
                                    {nepaliAmountFormat(
                                      (boq.boq_sum + boq.vat_amount).toFixed(2)
                                    )}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                    {!hideNoteTerms && (
                      <>
                        {notes && (
                          <div className="mt-4">
                            <label className="total">Notes</label>
                            <p dangerouslySetInnerHTML={{ __html: notes }}></p>
                          </div>
                        )}

                        {terms && (
                          <div className="mt-4">
                            <label className="total">Terms & Conditions</label>
                            <p dangerouslySetInnerHTML={{ __html: terms }}></p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="btn-option">
                  {(!isAuthenticate || isBidder(role)) &&
                    privateBid &&
                    Number(remaining_days) > 0 && (
                      <div className="btn-combination mr-2">
                        <div className="mg-btn-right">
                          <button
                            className="transparent-btn"
                            onClick={() => {
                              handleOnClickBid?.(tender_id as number);
                              toggleModal();
                            }}
                          >
                            <img
                              className="btn-img-icon-size"
                              src={require("../../images/bidblue.png")}
                              alt="images"
                            />
                            Bid
                          </button>
                        </div>
                      </div>
                    )}
                  {isBidder(role) && !savebidbtn && (
                    <button
                      className="save-bid-btn"
                      onClick={() => {
                        savedBids?.(tender_id as number);
                        toggleModal();
                      }}
                    >
                      <FontAwesomeIcon className="icon-bg" icon={faSave} />
                      Save Bids
                    </button>
                  )}

                  <div
                    onClick={() => {
                      handleDownload();
                    }}
                  >
                    <button className="delete-btn btn-margin">
                      <FontAwesomeIcon className="icon-bg" icon={faDownload} />
                      Download
                    </button>
                  </div>

                  <ReactToPrint
                    trigger={() => (
                      <button
                        className="delete-btn"
                        onClick={() => window.print()}
                      >
                        <FontAwesomeIcon className="icon-bg" icon={faPrint} />
                        Print
                      </button>
                    )}
                    content={() => componentRef.current}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>No data </div>
          )}
        </div>
      </div>
    </>
  );
};
